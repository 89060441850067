import { Component, Input, OnInit } from '@angular/core';
import { Item } from '@shared/models/item.model';

@Component({
  selector: 'app-job-item',
  templateUrl: './job-item.component.html',
  styleUrls: ['./job-item.component.scss']
})
export class JobItemComponent implements OnInit {
  @Input() jobItem: { item: Item; delQty: number; reqQty: number };

  constructor() {}

  ngOnInit(): void {}
}
