export class Material {
  // tslint:disable-next-line:variable-name
  _id: string;

  // tslint:disable-next-line:variable-name
  __v: string;

  myobCode: string;

  name: string;

  unit: string;

  type: string;

  projectCode: string;

  timberSize: string;

  areaPerUnit: string;

  constructor() {
    this.myobCode = '';
    this.name = '';
    this.unit = '';
    this.type = '';
    this.projectCode = null;
    this.timberSize = null;
    this.areaPerUnit = null;
  }
}
