import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  fromEvent,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { FormControl } from '@angular/forms';
import { GoogleMapsLoaderService } from '../google-maps-loader.service';
// import { MapsAPILoader } from '@googlemaps/js-api-loader';

@Component({
  selector: 'app-google-maps-autocomplete',
  templateUrl: './google-maps-autocomplete.component.html',
  styleUrls: ['./google-maps-autocomplete.component.scss'],
})
export class GoogleMapsAutocompleteComponent implements AfterViewInit {
  @ViewChild('search') searchRef: ElementRef;

  // autocomplete: google.maps.places.Autocomplete;

  private destroy$: Subject<void> = new Subject();

  addressFormControl = new FormControl();

  autocompleteOptions: google.maps.places.AutocompletePrediction[] = [];

  placeResult$ = new BehaviorSubject<google.maps.places.PlaceResult>(null);

  constructor(private loaderService: GoogleMapsLoaderService) {}

  ngAfterViewInit(): void {
    this.loaderService
      .loadAutoComplete(this.searchRef)
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.initAutocomplete$().subscribe();
  }

  initAutocomplete$() {
    return this.loaderService.autoComplete$.pipe(
      tap((autocomplete) => console.log('Autocomplete loaded', autocomplete)),
      takeUntil(this.destroy$)
    );
  }

  // displayFn(prediction: google.maps.places.AutocompletePrediction) {
  //   return prediction ? prediction.description : '';
  // }

  // onOptionSelected(event: any) {
  //   console.log(event.option.value);
  // }

  // autocomplete.addListener('place_changed', () => {
  //   // Get the selected place from the Autocomplete object
  //   const place = autocomplete.getPlace();
  //   return of(place);
  // });

  // autocomplete.setFields([
  //   'address_components',
  //   'formatted_address',
  //   'geometry',
  // ]);
  // this.addressFormControl.valueChanges
  //   .pipe(take(1))
  //   .subscribe((value) => {
  //     console.log(value);

  //     autocomplete.setBounds(new google.maps.LatLngBounds(value, value));
  //   });

  // autocomplete.addListener('predictions_changed', () => {
  //   const request = {
  //     input: this.searchRef.nativeElement.value,
  //     types: ['address'],
  //     componentRestrictions: {
  //       country: 'au',
  //     },
  //   };

  // const autocompleteService =
  //   new google.maps.places.AutocompleteService();

  // autocompleteService.getPlacePredictions(
  //   request,
  //   (predictions, status) => {
  //     if (status === google.maps.places.PlacesServiceStatus.OK) {
  //       this.autocompleteOptions = predictions;
  //     }
  //   }
  // );

  // autoComplete
  // this.loaderService
  //   .loadAutoComplete(this.searchRef)
  //   .pipe(
  //     map((autocomplete) => {
  //       console.log(autocomplete);

  //       autocomplete.addListener('place_changed', () => {
  //         // Get the selected place from the Autocomplete object
  //         const place = this.autocomplete.getPlace();

  //         // Do something with the selected place (e.g., set a property on your component)
  //         console.log(place);
  //       });
  //     }
  //     ),
  //     takeUntil(this.destroy$)).subscribe();

  // getLoader().load().then(() => {
  //   this.mapsAPILoader.load().then(() => {
  //     // Create a new Autocomplete object
  //     this.autocomplete = new google.maps.places.Autocomplete(
  //       this.searchBox.nativeElement
  //     );

  //     // Add a listener for the 'place_changed' event

  //   });
  // }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public clearAutocomplete() {
    this.searchRef.nativeElement.value = '';
  }
}
