<form [formGroup]="form">
  <mat-dialog-content>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field appearance="outline">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input formGroupName="range" [rangePicker]="picker">
            <input
              matStartDate
              formControlName="start"
              placeholder="Start date"
            />
            <input matEndDate formControlName="end" placeholder="End date" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <app-dropdown
          *ngIf="statusTypes"
          class="w-100"
          title="status"
          [control]="form.get('status')"
          [array]="statusTypes"
        >
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <app-location-search
          placeholder="From location"
          class="w-100"
          [control]="form.get('fromLocation')"
        >
        </app-location-search>
      </div>
      <div class="col-sm-6">
        <app-location-search
          placeholder="To location"
          class="w-100"
          [control]="form.get('toLocation')"
        >
        </app-location-search>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <app-dropdown
          *ngIf="employees"
          class="w-100"
          title="employee"
          [control]="form.get('employee')"
          [array]="employees"
          [props]="['firstName', 'lastName']"
        >
        </app-dropdown>
      </div>
      <div class="col-sm-4">
        <mat-form-field appearance="outline">
          <mat-label>ID</mat-label>
          <input matInput formControlName="_id" autocomplete="off" />
        </mat-form-field>
      </div>
      <div class="col-sm-4">
        <app-dropdown
          *ngIf="assignees"
          class="w-100"
          title="assignee"
          [control]="form.get('assignee')"
          [array]="assignees"
          [props]="['firstName', 'lastName']"
        >
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-slide-toggle formControlName="immutable">
          Include exported</mat-slide-toggle
        >
      </div>
      <div class="col-sm-6"></div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="d-flex justify-content-between w-100">
      <button
        class="mr-2"
        type="button"
        mat-raised-button
        color="warn"
        (click)="onClear()"
      >
        Clear
      </button>
      <div>
        <button
          mat-raised-button
          type="button"
          color="primary"
          mat-dialog-close
          [disabled]="form.invalid"
        >
          Search
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</form>
