<div *ngIf="job">
  <app-subheader>Details</app-subheader>

  <div class="row my-2">
    <div class="col-6 col-sm-3">
      <app-display
        title="assignee"
        [props]="[job.assignee.firstName, job.assignee.lastName]"
      ></app-display>
    </div>

    <div class="col-6 col-sm-3">
      <app-display
        title="Date"
        [props]="[job.assignedDate | date]"
      ></app-display>
    </div>

    <div class="col-6 col-sm-3">
      <h6 *ngIf="job.vehicle">
        <span class="material-icons green"> local_shipping </span>
        <div>
          {{ job.vehicle.colour }}
          {{ job.vehicle.make }}
          {{ job.vehicle.model }}
        </div>
      </h6>
    </div>

    <div class="col-6 col-sm-3">
      <app-display title="Type" [props]="[job.type]"></app-display>
    </div>
  </div>

  <div class="container">
    <app-display
      *ngIf="job.contact"
      title="contact"
      [props]="[job.contact?.name, job.contact?.number, job.contact?.position]"
    ></app-display>
    <h6 class="green">Instructions</h6>
    <textarea
      *ngIf="job.instruction"
      matInput
      rows="5"
      readonly
      class="text-dark my-2 w-100"
      [value]="job.instruction"
    ></textarea>
  </div>

  <!-- <div class="row">
    <div class="col-sm-6"></div>

    <div class="col-sm-6"></div>
  </div> -->
  <div class="my-3">
    <app-subheader>Locations</app-subheader>
    <div class="row">
      <div class="col-sm-6">
        <app-display
          [title]="'From - ' + job.fromLocation.name + '    '"
        ></app-display>
        <a
          mat-raised-button
          color="primary"
          class="d-block"
          [href]="
            'https://www.google.com/maps/place/?q=' +
            job.fromLocation.address.streetNumber +
            job.fromLocation.address.streetName +
            job.fromLocation.address.suburb +
            job.fromLocation.address.postCode
          "
        >
          {{ job.fromLocation.address.streetNumber }}
          {{ job.fromLocation.address.streetName }}
          {{ job.fromLocation.address.suburb }}
          {{ job.fromLocation.address.postCode }}
        </a>
      </div>

      <div class="col-sm-6">
        <app-display
          [title]="'To - ' + job.toLocation.name + '    '"
        ></app-display>
        <a
          mat-raised-button
          color="primary"
          class="d-block"
          [href]="
            'https://www.google.com/maps/place/?q=' +
            job.toLocation.address.streetNumber +
            job.toLocation.address.streetName +
            job.toLocation.address.suburb +
            job.toLocation.address.postCode
          "
        >
          {{ job.toLocation.address.streetNumber }}
          {{ job.toLocation.address.streetName }}
          {{ job.toLocation.address.suburb }}
          {{ job.toLocation.address.postCode }}
        </a>
      </div>
    </div>
  </div>

  <div class="p-2 my-3">
    <div *ngIf="job.materialItems?.length > 0" class="my-1">
      <app-subheader>Materials</app-subheader>
      <div class="row" *ngFor="let material of job.materialItems">
        <div class="col-sm-6">
          <div class="d-flex h-100 align-items-center">
            <h6>
              {{ material.item.name }}
              <small class="text-muted">
                {{ material.item.type }}
                {{ material.item.uom }}</small
              >
            </h6>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="d-flex justify-content-around">
            <div>
              <mat-form-field appearance="outline" [style.width.px]="90">
                <mat-label>Required</mat-label>
                <input
                  matInput
                  disabled
                  placeholder="Qty required"
                  [value]="material.reqQty"
                />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" [style.width.px]="90">
                <mat-label>Delivered</mat-label>
                <input
                  #delivered
                  matInput
                  placeholder="Qty delivered"
                  [(ngModel)]="material.delQty"
                />
              </mat-form-field>
            </div>
            <div class="d-flex align-items-center">
              <span
                *ngIf="material.delQty == material.reqQty"
                class="material-icons green"
              >
                done
              </span>
              <span
                *ngIf="material.delQty != material.reqQty"
                class="material-icons red"
              >
                error
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="job.timberItems?.length > 0" class="my-1">
      <app-subheader>Timber</app-subheader>
      <div class="row" *ngFor="let timber of job.timberItems">
        <div class="col-sm-6">
          <div class="d-flex h-100 align-items-center">
            <h6>
              {{ timber.item.name }}
              {{ timber.item?.projectCode }}
              <small class="text-muted">
                {{ timber.item.type }}
                {{ timber.item.uom }}
                <!-- {{ timber.item?.timberSize }} -->
                {{ timber.item?.areaPerUnit }}mm2
              </small>
            </h6>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="d-flex justify-content-around">
            <div>
              <mat-form-field appearance="outline" [style.width.px]="90">
                <mat-label>Required</mat-label>
                <input
                  matInput
                  disabled
                  placeholder="Qty required"
                  [value]="timber.reqQty"
                />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" [style.width.px]="90">
                <mat-label>Delivered</mat-label>
                <input
                  #delivered
                  matInput
                  placeholder="Qty delivered"
                  [(ngModel)]="timber.delQty"
                />
              </mat-form-field>
            </div>
            <div class="d-flex align-items-center">
              <span
                *ngIf="timber.delQty == timber.reqQty"
                class="material-icons green"
              >
                done
              </span>
              <span
                *ngIf="timber.delQty != timber.reqQty"
                class="material-icons red"
              >
                error
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div>
      <h6 class="green">Notes</h6>
      <textarea
        matInput
        rows="5"
        class="text-dark my-2 w-100"
        [(ngModel)]="job.notes"
      ></textarea>
    </div>
  </div>
</div>

{{ error | json }}
{{ job.images | json }}

<div class="my-2 text-center">
  <app-subheader>Images</app-subheader>
  <input
    type="file"
    accept="image/*"
    class="my-2"
    id="cameraInput"
    name="cameraInput"
    (change)="onFileSelected($event)"
  />
  <button type="button" class="btn btn-success" (click)="onUpload()">
    Upload Image
  </button>

  <!-- <label class="btn btn-success d-inline-block">
    Select Image
    <input
      id="files"
      name="file"
      type="file"
      class="d-none"
      (change)="onUpload($event)"
      color="primary"
      accept="image/*"
    />
  </label> -->
</div>

<div
  *ngIf="job.images?.length > 0"
  id="carousel{{ index }}"
  data-interval="false"
  class="carousel slide my-2"
>
  <div class="carousel-inner">
    <div class="carousel-item" *ngFor="let imageId of job.images">
      <img
        src="{{ BASE_URL }}/v1/images/{{ imageId }}"
        class="d-block w-100 h-100"
        alt="image"
      />
      <div class="carousel-caption">
        <button
          mat-raised-button
          color="warn"
          class="btn-remove"
          (click)="onRemove(imageId)"
        >
          <span class="material-icons"> delete_outline </span>
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="job.images.length > 1">
    <a
      class="carousel-control-prev"
      href="#carousel{{ index }}"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carousel{{ index }}"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </ng-container>
</div>
