<form [formGroup]="form" (submit)="onSubmit()">
  <h2 mat-dialog-title>
    {{ _id ? 'Edit Location' : 'Add Location' }}
  </h2>
  <mat-dialog-content>
    <input type="hidden" formControlName="_id" />
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="name"
            autocomplete="off"
            placeholder="Name"
          />
          <mat-error>Please enter location name</mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <app-dropdown
          [disabled]="form.get('warehouse').value"
          title="type"
          [control]="form.get('type')"
          [array]="['supplier', 'client']"
        ></app-dropdown>
      </div>
    </div>
    <mat-form-field appearance="outline" *ngIf="type.value === 'client'">
      <input
        matInput
        formControlName="ref"
        autocomplete="off"
        placeholder="Project #"
      />
    </mat-form-field>

    <mat-slide-toggle formControlName="manual">
      Manual Address
    </mat-slide-toggle>

    <div
      class="w-100 text-center py-2"
      [class.d-none]="form.get('manual').value"
    >
      <app-google-maps-autocomplete #autocomplete></app-google-maps-autocomplete>
    </div>
    <div *ngIf="form.get('manual').value" formGroupName="address">
      <div class="row">
        <div class="col-sm-3">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="streetNumber"
              autocomplete="off"
              placeholder="St.Number"
            />
            <mat-error>Please enter street number</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-9">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="streetName"
              autocomplete="off"
              placeholder="St.Name"
            />
            <mat-error>Please enter street name</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="postCode"
              autocomplete="off"
              placeholder="Postcode"
            />
            <mat-error>Please enter post code number</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-9">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="suburb"
              autocomplete="off"
              placeholder="Suburb"
            />
            <mat-error>Please enter suburb name</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="state"
              autocomplete="off"
              placeholder="State"
            />
            <mat-error>Please enter state name</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <mat-slide-toggle formControlName="warehouse">
      Is warehouse</mat-slide-toggle
    >
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="mr-2"
      type="button"
      mat-raised-button
      color="warn"
      (click)="onClear()"
    >
      Clear
    </button>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      [disabled]="form.invalid"
    >
      Submit
    </button>
  </mat-dialog-actions>
</form>
