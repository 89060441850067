<mat-expansion-panel hideToggle>
  <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
    <div class="row w-100 py-2">
      <div class="col-sm-6">
        <h6>
          {{ stock.item.name | titlecase }}

          <small class="d-block text-muted">
            {{ stock.item.type | titlecase }}
            <span *ngIf="stock.item.type === 'timber'" class="subtle">
              [{{ stock.item.projectCode }} - {{ stock.item.length }}/{{
                stock.item.width
              }}/{{ stock.item.thickness }}]
            </span>
          </small>
        </h6>
      </div>

      <div class="col-sm-2">
        <app-display
          title="Min Stock"
          [props]="[stock.item.minStock, stock.item.uom]"
        ></app-display>
      </div>

      <div class="col-sm-2">
        <app-display
          title="Est Stock"
          [error]="true"
          [props]="[getEstimatedStockLevel, stock.item.uom]"
        ></app-display>
      </div>

      <div class="col-sm-2">
        <app-display
          title="Current Stock"
          [error]="true"
          [props]="[getCurrentStockLevel, stock.item.uom]"
        ></app-display>
      </div>
    </div>
  </mat-expansion-panel-header>

  <h6 class="text-center">
    Order at least {{ getOrderAmount }} {{ stock.item.uom
    }}<span *ngIf="stock.item.minStock - stock.curQuantity > 0">s</span> of
    {{ stock.item.name }}
  </h6>
</mat-expansion-panel>
