import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input() control: UntypedFormControl;

  @Input() title: string;

  @Input() props: string[];

  @Input() array: any;

  @Input() disabled = false;

  @Input() object = false;

  @Output() select = new EventEmitter();

  constructor(private titlecasePipe: TitleCasePipe) {}

  ngOnInit(): void {}

  emit(element) {
    this.select.emit(element._id);
  }

  displayFn(element: any): string {
    if (this.props?.length) {
      // console.log('displaying', element);
      let display = '';

      this.props.forEach(prop => {
        display += `${prop.split('.').reduce((o, i) => o[i], element)} `;
      });

      return display;
    }
    return element;
  }

  compareItems(i1, i2) {
    if (i1?._id || i2?._id) {
      return i1 && i2 && i1._id === i2._id;
    }
    return i1 === i2;
  }
}
