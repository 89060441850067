import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env';
import { Observable } from 'rxjs';

export interface Response<T> {
  data?: T;
  status: string;
}

export class CrudService<T> {
  protected readonly apiUrl = `${this.baseUrl}/v1/${this.entityname}`;

  constructor(
    protected readonly http: HttpClient,
    protected readonly entityname: string,
    protected readonly baseUrl: string = environment.API_BASE_URL
  ) {}

  create(body: T): Observable<Response<T>> {
    return this.http.post<Response<T>>(this.apiUrl, body);
  }

  readAll(query?: { [key: string]: string }): Observable<Response<T[]>> {
    const params = new HttpParams({ fromObject: query });
    return this.http.get<Response<T[]>>(this.apiUrl, { params });
  }

  read(id: number): Observable<Response<T>> {
    const url = this.entityUrl(id);
    return this.http.get<Response<T>>(url);
  }

  update(id: number, body: T): Observable<Response<T>> {
    const url = this.entityUrl(id);
    return this.http.put<Response<T>>(url, body);
  }

  delete(id: number): Observable<Response<T>> {
    const url = this.entityUrl(id);
    return this.http.delete<Response<T>>(url);
  }

  protected entityUrl(id: number): string {
    return [this.apiUrl, id].join('/');
  }
}
