import { Injectable } from '@angular/core';
import {
  MatSnackBarRef,
  MatSnackBar,
  TextOnlySnackBar,
  MatSnackBarConfig
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  success = (
    message?: string,
    config?: MatSnackBarConfig
  ): MatSnackBarRef<TextOnlySnackBar> =>
    this.snackBar.open(
      message ? `Success: ${message}` : 'Success',
      'Dismiss',
      config
    );

  issue = (
    error?: unknown,
    config?: MatSnackBarConfig
  ): MatSnackBarRef<TextOnlySnackBar> =>
    this.snackBar.open(error ? `Issue: ${error}` : 'Issue', 'Dismiss', config);
}
