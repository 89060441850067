<nav class="navbar navbar-expand-lg navbar-light bg-white shadow">
  <!-- logo -->
  <a class="navbar-brand" routerLink="/">
    Be<span class="active">Floored</span>.</a
  >

  <!-- hamburger -->
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- navbar items -->
  <div
    class="collapse navbar-collapse"
    id="navbarSupportedContent"
    *ngIf="isLoggedIn()"
  >
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a
          class="nav-link underline-hover"
          routerLink=""
          [routerLinkActiveOptions]="{ exact: true }"
          routerLinkActive="active"
          >Home</a
        >
      </li>
      <li class="nav-item" *ngIf="isStaff()">
        <a
          class="nav-link underline-hover"
          routerLink="/job"
          routerLinkActive="active"
          >Jobs</a
        >
      </li>
      <li class="nav-item" *ngIf="isStaff()">
        <a
          class="nav-link underline-hover"
          routerLink="/inventory"
          routerLinkActive="active"
          >Inventory</a
        >
      </li>

      <li class="nav-item">
        <!-- *ngIf="!isStaff()" -->
        <a
          class="nav-link underline-hover"
          routerLink="/log"
          routerLinkActive="active"
          >logs</a
        >
      </li>

      <li class="nav-item" *ngIf="isStaff()">
        <a
          class="nav-link underline-hover"
          routerLink="/settings"
          routerLinkActive="active"
        >
          <!-- <span class="material-icons icon">
        </span> -->
          Settings
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link underline-hover" (click)="logout()">
          <span class="material-icons icon">
            logout
          </span>
          <!-- Settings -->
        </a>
      </li>
    </ul>
    <!-- search bar -->
    <!-- <form class="form-inline my-2 my-lg-0">
        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
      </form> -->
  </div>
</nav>
