import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '@shared/models/user.model';
import { environment } from '@env';
import { Observable } from 'rxjs';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
} from '@angular/forms';
import { authRoles, Role } from '@assets/config/config';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // TODO get this from server
  // role = ['admin', 'user'];

  // when noAuthHeader is applied to a request the http interceptor will not apply the jwt token
  noAuthHeader = {
    headers: new HttpHeaders({ NoAuth: 'True' }),
  };

  constructor(private http: HttpClient, private fb: UntypedFormBuilder) {
    // this.getCurrentUser().subscribe(res => {
    //   this.user = res.data[0];
    // });
  }

  form: UntypedFormGroup = this.getForm();
  // user: User;

  getForm(user?: User) {
    return this.fb.group({
      _id: new UntypedFormControl(user ? user._id : null),
      firstName: new UntypedFormControl(
        user ? user.firstName : undefined,
        Validators.required
      ),
      lastName: new UntypedFormControl(
        user ? user.lastName : undefined,
        Validators.required
      ),
      email: new UntypedFormControl(
        user ? user.email : undefined,
        Validators.compose([Validators.required, Validators.email])
      ),
      password: new UntypedFormControl(user ? user.password : undefined, [
        Validators.minLength(8),
      ]),
      passwordConfirm: new UntypedFormControl(
        user ? user.passwordConfirm : undefined,
        [Validators.minLength(8)]
      ),
      role: new UntypedFormControl(
        user ? user.role : undefined,
        Validators.required,
        Validators.minLength[4]
      ),
    });
  }

  resetForm(): void {
    this.form.reset();
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setErrors(null);
    });
  }

  populateForm(user: User): void {
    this.form.patchValue(user);
  }

  // ** HTTP methods **

  // Sign a user up
  registerUser(user: User) {
    return this.http.post(`${environment.API_BASE_URL}/v1/users/signup`, user);
  }

  // sends a user to backend for login authentication
  loginUser(user: User) {
    return this.http.post(`${environment.API_BASE_URL}/v1/users/login`, user);
  }

  // get the current user
  getCurrentUser(): any {
    return this.http.get(`${environment.API_BASE_URL}/v1/users/me`);
  }

  // search for users
  search(val, limit: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/users/search/${val}`, {
      params: { limit },
    });
  }

  getAllUsers(params?: Params): any {
    return this.http.get(`${environment.API_BASE_URL}/v1/users`, { params });
  }

  getUsersByRole(roles: string[]): Observable<any> {
    let params;
    if (roles.length > 1) {
      params = { 'role[or]': roles.join(',') };
    } else {
      params = { role: roles[0] };
    }
    return this.http.get(`${environment.API_BASE_URL}/v1/users`, {
      params,
    });
  }

  // delete user
  deleteUser(id: string): Observable<any> {
    return this.http.delete(`${environment.API_BASE_URL}/v1/users/${id}`);
  }

  createUser(user: User): any {
    return this.http.post(`${environment.API_BASE_URL}/v1/users/signup`, user);
  }

  // update user
  updateUser(user: User): Observable<any> {
    return this.http.patch(
      `${environment.API_BASE_URL}/v1/users/${user._id}`,
      user
    );
  }

  updatePassword(user: User): Observable<any> {
    return this.http.patch(
      `${environment.API_BASE_URL}/v1/users/updatePassword`,
      user
    );
  }
  // // get user by id
  // getUserById(id: string): Observable<any> {
  //   return this.http.get(`${environment.API_BASE_URL}/v1/users//${id}`);
  // }

  // // get the logged in user id
  // getUserId(): string {
  //   return this.getUserPayload()._id;
  // }

  // ** Helper methods **

  // TODO move these to auth controller
  // retrieves the current token from localstorage
  getToken(): string {
    return localStorage.getItem('token');
  }

  getRole(): string {
    return localStorage.getItem('role');
  }

  getId(): string {
    return localStorage.getItem('id');
  }

  // saves the authentication token to local storage
  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  setRole(role: string): void {
    localStorage.setItem('role', role);
  }

  setId(id: string): void {
    localStorage.setItem('id', id);
  }

  deleteToken(): void {
    localStorage.removeItem('token');
  }

  // returns the payload section of a token
  getUserPayload(): any {
    const token = this.getToken();
    if (token) {
      const userPayload = atob(token.split('.')[1]);
      return JSON.parse(userPayload);
    }
    return null;
  }

  isLoggedIn(): boolean {
    const userPayload = this.getUserPayload();
    if (userPayload) {
      return userPayload.exp > Date.now() / 1000;
    }

    return false;
  }

  hasPermission(role: Role): boolean {
    const lowercaseRole = role.toString().toLowerCase();
    const requiredRoles = authRoles[lowercaseRole];
    const userRole = localStorage.getItem('role');

    return requiredRoles.includes(userRole);
  }
}
