<section class="login w-75 shadow container mt-5 bg-white rounded pt-4">
  <div class="text-center mb-2">
    <span class="display-5 p-2 active">Login</span>
    <!-- <span class="display-5 p-2 subtle" routerLink="/register">Register</span>  -->
  </div>

  <div
    *ngIf="showSuccessfulAlert"
    class="alert alert-success mt-3 text-center"
    role="alert"
  >
    Login was successful!
  </div>

  <div
    *ngIf="showUnsuccessfulAlert"
    class="alert alert-danger mt-3 text-center"
    role="alert"
  >
    Login was unsuccessful!
    <span>{{ error }}</span>
  </div>

  <mat-spinner class="mx-auto mt-5" *ngIf="loading"></mat-spinner>

  <form
    [formGroup]="loginForm"
    (ngSubmit)="onSubmit()"
    class="text-center py-3"
  >
    <div class="text-center h-3">
      <img
        src="assets/img/profile-icon.png"
        class="img-fluid img m-3"
        alt="..."
      />
    </div>

    <!-- email input -->
    <div class="form-group">
      <input
        type="email"
        id="emailInput"
        placeholder="Email"
        class="form-control w-75 text-center mx-auto"
        [ngClass]="{
          'has-error':
            (form.email.dirty || form.email.touched) && form.email.errors
        }"
        formControlName="email"
        required
        email
      />
      <div
        *ngIf="(form.email.dirty || form.email.touched) && form.email.errors"
        class="error"
      >
        <div *ngIf="form.email.errors.required">Email is required</div>
        <div *ngIf="form.email.errors.email">Please enter a valid email</div>
      </div>
    </div>

    <!-- password input -->
    <div class="form-group">
      <input
        type="password"
        id="passwordInput"
        placeholder="password"
        class="form-control w-75 text-center mx-auto"
        [ngClass]="{
          'has-error':
            (form.password.dirty || form.password.touched) &&
            form.password.errors
        }"
        formControlName="password"
        required
        password
        minlength="4"
      />
      <div
        *ngIf="
          (form.password.dirty || form.password.touched) && form.password.errors
        "
        class="error"
      >
        <div *ngIf="form.password.errors.required">password is required</div>
        <div *ngIf="form.password.errors.minlength">
          Password must be atleast 4 characters
        </div>
      </div>
    </div>

    <button
      type="submit"
      [disabled]="loginForm.invalid"
      class="btn btn-success shadow"
    >
      Submit
    </button>
  </form>
</section>
