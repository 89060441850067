import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LocationService } from '@core/services/location.service';

@Component({
  selector: 'app-location-input',
  templateUrl: './location-input.component.html',
  styleUrls: ['./location-input.component.scss']
})
export class LocationInputComponent implements OnInit {
  locations;

  constructor(private locationService: LocationService) {}

  @Input() control: UntypedFormControl;

  @Input() title: string;

  ngOnInit(): void {
    console.log(this.control);

    this.locationService.getAllLocations().subscribe(res => {
      if (res.status === 'success') {
        this.locations = res.data as Location[];
      }
    });
  }
}
