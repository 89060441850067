<form [formGroup]="form">
  <mat-dialog-content>
    <mat-form-field appearance="outline">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date" />
        <input matEndDate formControlName="end" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="d-flex justify-content-between w-100">
      <button
        class="mr-2"
        type="button"
        mat-raised-button
        color="warn"
        (click)="onClear()"
      >
        Clear
      </button>
      <div>
        <button
          mat-raised-button
          type="button"
          color="primary"
          (click)="onExport()"
          [disabled]="form.invalid"
        >
          Export
        </button>
        <button
          mat-raised-button
          type="button"
          color="primary"
          (click)="onSearch()"
          [disabled]="form.invalid"
        >
          Search
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</form>
