import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ItemService } from '@core/services/item.service';
import { Item } from '@shared/models/item.model';

@Component({
  selector: 'app-item-search',
  templateUrl: './item-search.component.html',
  styleUrls: ['./item-search.component.scss']
})
export class ItemSearchComponent implements OnInit {
  options: Item[] = [];

  timeout = null; // default set

  helperString = 'Use quotes for exact match eg. "BF20-0047"';

  @Input() placeholder = 'Item';

  @Output() emitter = new EventEmitter();

  @Input() control = new UntypedFormControl();

  @Input() type = null;

  constructor(private itemService: ItemService) {}

  ngOnInit(): void {
    this.control.valueChanges.subscribe(val => {
      if (typeof val === 'object') {
        this.emitter.emit(val);
      }
    });
  }

  onKeyup(val) {
    const _this = this;
    // If it has already been set cleared first.
    clearTimeout(this.timeout);

    // set timeout to 1000ms
    this.timeout = setTimeout(function() {
      // call a search to backend for items
      _this.search(val);
      console.log('Input Value:', val);
    }, 700);
  }

  search(val) {
    if (val) {
      this.itemService.search(val, this.type).subscribe(res => {
        if (res.status === 'success') {
          this.options = res.data;
        }
      });
    }
  }

  getIcon(type: string) {
    if (type === 'timber') {
      return 'clear_all';
    }
    return 'category';
  }

  displayFn(item: Item) {
    if (item && item.name) {
      let display = item.name;
      if (item.type === 'timber') {
        display += ' [';
        display += `${item.projectCode} | `;
        display += `${item.length}/`;
        display += `${item.width}/`;
        display += `${item.thickness}] `;
      }
      display += ` | ${item.type}`;
      display += ` | ${item.uom}`;

      return display;
    }
    return '';
  }
}
