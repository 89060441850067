import { Injectable } from '@angular/core';
import { Stock } from '@shared/models/stock.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { UntypedFormBuilder } from '@angular/forms';
import { LocationService } from './location.service';
import { ItemService } from './item.service';

@Injectable({
  providedIn: 'root',
})
export class StockService {
  constructor(private http: HttpClient) { }
  // ** HTTP methods **

  // post a stock to be created in the backend
  createStock(stock: Stock): Observable<any> {
    return this.http.post(`${environment.API_BASE_URL}/v1/stocks`, stock);
  }

  // post stocks to be created in the backend
  createStocks(stocks: Array<Stock>): Observable<any> {
    return this.http.post(`${environment.API_BASE_URL}/v1/stocks`, stocks);
  }

  getStockByQuery(query: any): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/stocks`, {
      params: query,
    });
  }

  getLowStocks(locationId): Observable<any> {
    return this.http.get(
      `${environment.API_BASE_URL}/v1/stocks/low/${locationId}`
    );
  }

  // get stock by id
  getStockById(id: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/stocks/${id}`);
  }

  // get all stocks
  getAllStocks(): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/stocks`);
  }

  // get all stocks
  getRecentStocks(): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/stocks`, {
      params: {
        sort: '-createdAt',
      },
    });
  }

  checkAndPopulateStock(itemID: string, qty: number): Observable<any> {
    return this.http.post('/api/stock/check-and-populate', { itemID, qty });
  }

  getStocksByItemType(type: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/stocks/type/${type}`);
  }

  getStocksByLocation(location: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/stocks`, {
      params: { location, limit: '300' },
    });
  }

  // update a stock
  updateStock(stock: Stock) {
    return this.http.patch(
      `${environment.API_BASE_URL}/v1/stocks/${stock._id}`,
      stock
    );
  }

  // delete stock
  deleteStock(id: string): Observable<any> {
    return this.http.delete(`${environment.API_BASE_URL}/v1/stocks/${id}`);
  }
}
