import { Router } from "@angular/router";

export function getDirtyValues(form: any) {
  const dirtyValues = {};

  Object.keys(form.controls).forEach(key => {
    const currentControl = form.controls[key];

    if (currentControl.dirty) {
      if (currentControl.controls) {
        dirtyValues[key] = this.getDirtyValues(currentControl);
      } else {
        dirtyValues[key] = currentControl.value;
      }
    }
  });

  return dirtyValues;
}

export function round(number: number) {
  return Math.round(number * 100) / 100;
}

// export function openInNewTab(router: Router, namedRoute: string) {
//   let newRelativeUrl = router.createUrlTree([namedRoute]);
//   let baseUrl = window.location.href.replace(router.url, '');

//   window.open(baseUrl + newRelativeUrl, '_blank');
// }
