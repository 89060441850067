import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { authRoles } from '@assets/config/config';
import { LoginComponent } from '@core/authentication/login/login.component';
import { AuthGuard } from '@core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('@modules/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthGuard],
    data: { roles: authRoles.staff },
  },
  {
    path: 'inventory',
    loadChildren: () =>
      import('@modules/inventory/inventory.module').then(
        (m) => m.InventoryModule
      ),
    canActivate: [AuthGuard],
    data: { roles: authRoles.staff },
  },
  {
    path: 'job',
    loadChildren: () =>
      import('@modules/job/job.module').then((m) => m.JobModule),
    canActivate: [AuthGuard],
    data: { roles: authRoles.staff },
  },
  {
    path: 'log',
    loadChildren: () =>
      import('@modules/log/log.module').then((m) => m.LogModule),
    canActivate: [AuthGuard],
    data: { roles: authRoles.driver },
  },
  {
    path: '',
    loadChildren: () =>
      import('@modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
    data: { roles: authRoles.driver },
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
