import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-job-form-item',
  templateUrl: './job-form-item.component.html',
  styleUrls: ['./job-form-item.component.scss']
})
export class JobFormItemComponent implements OnInit {
  @Input() jobItem;

  @Input() timber = true;

  @Input() stock;

  @Input() job = true;

  @Output() remove = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onRemove(): void {
    this.remove.emit();
  }
}
