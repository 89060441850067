import { Component, Input, OnInit } from '@angular/core';
import { Stock } from '@shared/models/stock.model';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @Input() stocks: Stock[];

  constructor() {}

  groupedStocks;

  ngOnInit(): void {
    this.groupedStocks = this.group(this.stocks);
    console.log(this.groupedStocks);
  }

  group = stocks => {
    const result = [];
    for (let i = 0; i < stocks.length; i += 3) {
      result.push(stocks.slice(i, i + 3));
    }

    return result;
  };
}
