<form class="example-form">
  <mat-form-field appearance="outline" class="example-full-width">
    <input
      type="text"
      [placeholder]="placeholder"
      aria-label="Number"
      matInput
      (keyup)="onKeyup($event.target.value)"
      [formControl]="control"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of options" [value]="option">
        <small>
          {{ displayFn(option) }}
        </small>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
