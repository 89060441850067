import { Component, Input, OnInit } from '@angular/core';
import { Job } from '@shared/models/job.model';

@Component({
  selector: 'app-admin-job-view-header',
  templateUrl: './admin-job-view-header.component.html',
  styleUrls: ['./admin-job-view-header.component.scss']
})
export class AdminJobViewHeaderComponent implements OnInit {
  @Input()
  public job: Job;

  constructor() {}

  ngOnInit() {}
}
