import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Job } from '@shared/models/job.model';
import { Observable } from 'rxjs';
import { environment } from '@env';
import {
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
} from '@angular/forms';
import { Stock } from '@shared/models/stock.model';
import { User } from '@shared/models/user.model';

import { Contact } from '@shared/models/contact.model';

import { MatDialog } from '@angular/material/dialog';
import { Location } from '@shared/models/location.model';
import { Vehicle } from '@shared/models/vehicle.model';
import { LocationFormComponent } from '@shared/components/location-form/location-form.component';
import { ContactFormComponent } from '@shared/components/contact-form/contact-form.component';
import * as utils from '@shared/utils/helper.util';
import { map, tap } from 'rxjs/operators';
import { VehicleService } from './vehicle.service';
import { UserService } from '.';
import { LocationService } from './location.service';
import { ContactService } from './contact.service';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  public statusTypes: string[] = [ 'draft', 'active', 'review', 'complete' ];

  public users: User[];

  public contacts: Contact[];

  public locations: Location[] = [];

  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private contactService: ContactService,
    private locationService: LocationService,
    private vehicleService: VehicleService,
    private userService: UserService,
    private dialog: MatDialog
  ) {
    this.getLocations();
    this.getContacts();
    this.getUsers();
  }

  form: UntypedFormGroup = this.getForm();

  getForm(job?: Job) {
    return this.fb.group({
      _id: new UntypedFormControl(job ? job._id : null),
      ref: new UntypedFormControl(job ? job.ref : null),
      createdAt: new UntypedFormControl(job ? job.createdAt : new Date()),
      assignedDate: new UntypedFormControl(job ? job.assignedDate : new Date()),
      assignedTime: new UntypedFormControl(job ? job.assignedTime : '08:00'),
      assignee: new UntypedFormControl(
        job ? job.assignee : this.userService.getCurrentUser()
      ),
      employees: new UntypedFormControl(job ? job.employees : null),
      toLocation: new UntypedFormControl(
        job ? job.toLocation : null,
        Validators.required
      ),
      fromLocation: new UntypedFormControl(
        job ? job.fromLocation : null,
        Validators.required
      ),
      contact: new UntypedFormControl(job ? job.contact : null),
      vehicle: new UntypedFormControl(job ? job.vehicle : null),
      status: new UntypedFormControl(job ? job.status : 'draft'),
      type: new UntypedFormControl(job ? job.type : '', Validators.required),
      instruction: new UntypedFormControl(job ? job.instruction : ''),
      notes: new UntypedFormControl(job ? job.notes : ''),
      images: new UntypedFormControl(job ? job.images : []),
      immutable: new UntypedFormControl(job ? job.immutable : false),
      materialItems: job
        ? this.getFormArray(job.materialItems)
        : new UntypedFormArray([]),
      timberItems: job
        ? this.getFormArray(job.timberItems)
        : new UntypedFormArray([]),
      // items: (job ? this.getFormArray(job.items) : new FormArray([])),
    });
  }

  getFormArray(objectArray?: any[]): UntypedFormArray {
    const formArray = new UntypedFormArray([]);
    if (objectArray) {
      objectArray.forEach((object) => {
        formArray.push(this.getJobItemGroup(object));
      });
    }
    return formArray;
  }

  getJobItemGroup(object?: any): UntypedFormGroup {
    return this.fb.group({
      item: new UntypedFormControl(object ? object.item : null, [
        Validators.required,
      ]),
      reqQty: new UntypedFormControl(object ? object.reqQty : 0),
      delQty: new UntypedFormControl(object ? object.delQty : 0),
    });
  }

  setAssignee(user: User) {
    this.form.setControl('assignee', this.userService.getForm(user));
  }

  setEmployee(user: User) {
    this.form.setControl('employees', this.userService.getForm(user));
  }

  setStatus(status: string) {
    this.form.controls.status.setValue(status);
  }

  setToLocation(location: Location) {
    this.form.setControl('toLocation', this.locationService.getForm(location));
  }

  setFromLocation(location: Location) {
    this.form.setControl(
      'fromLocation',
      this.locationService.getForm(location)
    );
  }

  setVehicle(vehicle: Vehicle) {
    this.form.setControl('vehicle', this.vehicleService.getForm(vehicle));
  }

  setSiteContact(contact: Contact) {
    this.form.setControl('siteContact', this.contactService.getForm(contact));
  }

  addMaterialItem() {
    (this.form.get('materialItems') as UntypedFormArray).push(
      this.getJobItemGroup()
    );
  }

  addTimberItem() {
    (this.form.get('timberItems') as UntypedFormArray).push(
      this.getJobItemGroup()
    );
  }

  deleteMaterial(i) {
    (this.form.get('materialItems') as UntypedFormArray).removeAt(i);
  }

  deleteTimber(i) {
    (this.form.get('timberItems') as UntypedFormArray).removeAt(i);
  }

  onCreateLocation() {
    this.dialog
      .open(LocationFormComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.status) {
          this.locations.push(result.location as Location);
          this.form.get('location').setValue(result.location);
        }
      });
  }

  onCreateContact() {
    this.dialog
      .open(ContactFormComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.status) {
          this.contacts.push(result.contact as Contact);
          this.form.get('siteContact').setValue(result.contact);
        }
      });
  }

  getUsers() {
    this.userService.getAllUsers().subscribe((data) => {
      this.users = data.users;
    });
  }

  getContacts() {
    this.contactService.getAllContacts().subscribe((data) => {
      this.contacts = data.contacts;
    });
  }

  getLocations() {
    this.locationService.getAllLocations().subscribe((data) => {
      this.locations = data.locations;
    });
  }

  defaultForm() {
    this.form = this.getForm();
  }

  resetForm() {
    this.form = this.getForm();
    // this.form.reset();
    // Object.keys(this.form.controls).forEach(key => {
    //   this.form.get(key).setErrors(null);
    //   this.form.get(key).enable();
    // });
  }

  populateForm(job: Job) {
    console.log('populating form', job);
    this.form = this.getForm(job);
    // this.form.patchValue(job);
  }

  // ** HTTP methods **

  validateJob() {
    // filter out fields that are not changed
    const job = utils.getDirtyValues(this.form) as any;

    // job.items = job.materialItems.concat(job.timberItems);
    // turn objects into ids;
    job.items.forEach((el, i) => {
      job.items[ i ].item = job.items[ i ].item._id;
    });

    // post to backend
  }

  // post a job to be created in the backend
  createJob(job: Job): Observable<any> {
    return this.http.post(`${environment.API_BASE_URL}/v1/jobs`, job);
  }

  getJobsByStatus(status): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/jobs`, {
      params: {
        status,
        type: 'job',
        sort: '-assignedDate',
      },
    });
  }

  getActiveJobsByUserId(id: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/jobs`, {
      params: {
        status: 'active',
        employees: id,
        sort: 'assignedDate assignedTime',
      },
    });
  }

  getReviewJobsByUserId(id: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/jobs`, {
      params: {
        status: 'review',
        employees: id,
        sort: 'assignedDate assignedTime',
      },
    });
  }

  exportCSV(query?): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/jobs/csv`, {
      params: query,
      headers: new HttpHeaders({}),
      responseType: 'text',
    });
  }

  // get all jobs
  getAllJobs(): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/jobs`);
  }

  // get breakdown of jobs per stock
  getJobBreakdown(locationId, itemId): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/jobs/breakdown`, {
      params: { location: locationId, item: itemId },
    });
  }

  // get all jobs
  getJobById(jobId: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/jobs/${jobId}`);
  }

  // search jobs with a query object
  searchJobs(query): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/jobs`, {
      params: query,
    });
  }

  // get all active jobs
  getActiveJobs(): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/jobs`, {
      params: {
        status: 'active',
        type: 'job',
        sort: 'assignedTime',
      },
    });
  }

  getJobsByType(type): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/jobs`, {
      params: {
        type,
        sort: '-assignedDate assignedTime',
        limit: '700',
      },
    });
  }

  // get all review jobs
  getReviewJobs(): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/jobs`, {
      params: { status: 'review', type: 'job' },
    });
  }

  // update a job
  updateJob(job: Job, formData?) {
    return this.http.patch(
      `${environment.API_BASE_URL}/v1/jobs/${job._id}`,
      formData || job
    );
  }

  // complete a job
  completeJob(job: Job) {
    return this.http.put(
      `${environment.API_BASE_URL}/v1/jobs/complete/${job._id}`,
      job
    );
  }

  // delete job
  deleteJob(id: string): Observable<any> {
    return this.http.delete(`${environment.API_BASE_URL}/v1/jobs/${id}`);
  }
}
