<form [formGroup]="form" (submit)="onSubmit()">
  <h2 mat-dialog-title>
    {{ id ? 'Edit Vehicle' : 'Add Vehicle' }}
  </h2>
  <mat-dialog-content>
    <input type="hidden" formControlName="_id" />
    <div class="row">
      <div class="col-4">
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="colour"
            autocomplete="off"
            placeholder="colour"
          />
          <mat-error>Please enter colour</mat-error>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="make"
            autocomplete="off"
            placeholder="make"
          />
          <mat-error>Please enter make</mat-error>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="model"
            autocomplete="off"
            placeholder="model"
          />
          <mat-error>Please enter model</mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="mr-2"
      type="button"
      mat-raised-button
      color="warn"
      (click)="onClear()"
    >
      Clear
    </button>
    <button
      mat-raised-button
      #submit
      type="submit"
      color="primary"
      [disabled]="form.invalid"
    >
      Submit
    </button>
  </mat-dialog-actions>
</form>
