import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Job } from '@shared/models/job.model';
import { JobService } from '@core/services/job.service';
import { ImageService } from '@core/services/image.service';
import { environment } from '@env';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpEventType } from '@angular/common/http';

declare let $: any;

// class ImageSnippet {
//   constructor(public src: string, public file: File) { }
// }

@Component({
  selector: 'app-employee-job-view',
  templateUrl: './employee-job-view.component.html',
  styleUrls: ['./employee-job-view.component.scss']
})
export class EmployeeJobViewComponent implements OnInit, AfterViewInit {
  @Input() index: number;

  @Input() job: Job;

  BASE_URL = environment.API_BASE_URL;

  selectedFile: File = null;

  error = null;

  constructor(
    private jobService: JobService,
    private imageService: ImageService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.initCarousel();
  }

  initCarousel() {
    if (this.job.images.length) {
      const NextElement = $(`#carousel${this.index}`)
        .find('.carousel-item')
        .first();
      NextElement.addClass('active');
    }
  }

  onRemove(imageId: string) {
    this.imageService.removeImage(imageId).subscribe(res => {
      if (res && res.status) {
        this.job.images.splice(this.job.images.indexOf(imageId), 1);

        const ActiveElement = $(`#carousel${this.index}`).find(
          '.carousel-item.active'
        );
        ActiveElement.remove();
        const NextElement = $(`#carousel${this.index}`)
          .find('.carousel-item')
          .first();
        NextElement.addClass('active');

        this.onSave();
        this.snackbar.open('Success', 'Dismiss');
        // call carousel.next
      } else {
        this.snackbar.open('Issue', 'Dismiss');
      }
    });
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0] as File;
  }

  onUpload() {
    const formData = new FormData();
    formData.append('file', this.selectedFile, 'file');
    // console.log(this.selectedFile);

    // this.snackbar.open(this.selectedFile.type, 'dismiss', { duration: 10000 });

    this.imageService.createImage(formData).subscribe(
      res => {
        console.log(res);

        if (res.type === HttpEventType.UploadProgress) {
          this.snackbar.open(
            `${Math.round(res.loaded / res.total) * 100}%`,
            'dismiss'
          );
        } else if (res.body) {
          this.snackbar.open(res.body.data.id, 'dismiss');

          this.job.images.unshift(res.body.data.id);
          setTimeout(() => {
            const ActiveElement = $(`#carousel${this.index}`).find(
              '.carousel-item.active'
            );
            ActiveElement.removeClass('active');
            const NextElement = $(`#carousel${this.index}`)
              .find('.carousel-item')
              .first();
            NextElement.addClass('active');
          }, 200);
          this.onSave();
        }
      },
      error => {
        this.error = error;
        this.snackbar.open(error.error.message, 'dismiss', { duration: 10000 });
      }
    );
  }

  onSave() {
    const updatedJob = {
      _id: this.job._id,
      images: this.job.images
    };

    this.jobService.updateJob(this.job, updatedJob).subscribe(
      (res: any) => {
        this.snackbar.open('Success', 'Dismiss');
      },
      error => {
        this.snackbar.open(error.error.message, 'Dismiss');
      }
    );
  }
}
