<div class="d-flex px-4 justify-content-between border-bottom">
  <div class="align-self-center">
    <button mat-raised-button color="primary" (click)="onCreate()">
      <mat-icon>add</mat-icon>
      <span class="mt-2">Create</span>
    </button>
    <mat-slide-toggle
      *ngIf="inactiveToggle"
      class="pl-2"
      (change)="toggle($event)"
      [(checked)]="showInactive"
    >
      Inactivate
    </mat-slide-toggle>
  </div>

  <mat-form-field appearance="outline" class="w-50 mt-3" floatLabel="never">

    <input
  type="text"
  placeholder="Search"
  autocomplete="off"
  matInput
  [(ngModel)]="searchKey"
  (input)="onSearchChange()"
/>
  </mat-form-field>
</div>
