<mat-form-field appearance="outline" *ngIf="array">
  <mat-label>{{ title | titlecase }}</mat-label>
  <mat-select
    [formControl]="control"
    [compareWith]="compareItems"
    [disabled]="disabled"
  >
    <mat-option [value]="null"> -- </mat-option>
    <mat-option
      (onSelectionChange)="emit(element)"
      *ngFor="let element of array"
      [value]="element"
    >
      {{ displayFn(element) | titlecase }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="control.errors && control.touched">Invalid</mat-error>
</mat-form-field>
