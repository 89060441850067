import { Component, OnInit } from '@angular/core';
import { ContactService } from '@core/services/contact.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Contact } from '@shared/models/contact.model';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  public companies: string[] = [];

  public filteredOptions: Observable<string[]>;

  public positions: string[] = [];

  constructor(
    private contactService: ContactService,
    public dialogRef: MatDialogRef<ContactFormComponent>,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getCompanies();
    this.getPositions();

    this.dialogRef.afterClosed().subscribe(() => {
      this.onClear();
    });
    this.filteredOptions = this.form.get('company').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  onClear(): void {
    this.contactService.resetForm();
  }

  get form(): UntypedFormGroup {
    return this.contactService.form;
  }

  get _id(): string {
    return this.form.get('_id').value;
  }

  getPositions(): void {
    this.contactService.getContactPositions().subscribe(res => {
      if (res.status === 'success') {
        this.positions = res.data;
      } else {
        // TODO Make this a toastr
        console.log('error retrieving positions');
      }
    });
  }

  getCompanies(): void {
    this.contactService.getContactCompanies().subscribe(res => {
      if (res.status === 'success') {
        this.companies = res.data;
      } else {
        // TODO Make this a toastr
        console.log('error retrieving companies');
      }
    });
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.companies.filter(company =>
      company.toLowerCase().includes(filterValue)
    );
  }

  onSubmit(): void {
    const contact = this.contactService.form.value as Contact;
    if (!contact._id) {
      delete contact._id;
      this.contactService.createContact(contact).subscribe(
        res => {
          this.dialogRef.close(res);
        },
        error => {
          this.snackbar.open(error.error.message, 'Dismiss');
        }
      );
    } else {
      this.contactService.updateContact(contact).subscribe(
        res => {
          this.dialogRef.close(res);
        },
        error => {
          this.snackbar.open(error.error.message, 'Dismiss');
        }
      );
    }
  }
}
