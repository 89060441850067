<div *ngIf="job">
  <div class="row my-2">
    <div class="col-sm-3">
      <app-display
        title="assignee"
        [props]="[job.assignee?.firstName, job.assignee?.lastName]"
      ></app-display>
    </div>
    <div class="col-sm-3">
      <h6 *ngIf="job.vehicle">
        <span class="material-icons green"> local_shipping </span>
        <span class="text-dark d-block">
          {{ job.vehicle?.make }}
          {{ job.vehicle?.model }}
          {{ job.vehicle?.colour }}
        </span>
      </h6>
    </div>

    <div class="col-sm-3">
      <app-display
        *ngIf="job.contact"
        title="contact"
        [props]="[job.contact?.name, job.contact?.number]"
      ></app-display>
    </div>

    <div class="col-sm-3">
      <mat-form-field appearance="outline" appearance="outline">
        <mat-label>Time</mat-label>
        <input
          class="text-center"
          matInput
          [(ngModel)]="job.assignedTime"
          type="time"
        />
      </mat-form-field>

      <div class="text-center">
        <button
          mat-raised-button
          color="primary"
          [disabled]="job.assignedTime === initialTime"
          (click)="onChange()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
  <div class="my-3">
    <app-subheader>Locations</app-subheader>
    <div class="row">
      <div class="col-sm-6">
        <app-display
          [title]="'From - ' + job.fromLocation?.name"
          [props]="[
            job.fromLocation?.address.streetNumber,
            job.fromLocation?.address.streetName
          ]"
        ></app-display>
      </div>
      <div class="col-sm-6">
        <app-display
          [title]="'To - ' + job.toLocation?.name"
          [props]="[
            job.toLocation?.address.streetNumber,
            job.toLocation?.address.streetName
          ]"
        >
        </app-display>
      </div>
    </div>
  </div>

  <div class="p-2 my-3">
    <div *ngIf="job.materialItems?.length > 0" class="my-1">
      <app-subheader>Materials</app-subheader>
      <div class="row" *ngFor="let material of job.materialItems">
        <div class="col-sm-6">
          <div class="d-flex h-100 align-items-center">
            <h6>
              {{ material.item.name }}
              <small class="text-muted">
                {{ material.item.type }}
                {{ material.item.uom }}</small
              >
            </h6>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="d-flex justify-content-around">
            <div>
              <mat-form-field appearance="outline" [style.width.px]="90">
                <mat-label>Required</mat-label>
                <input
                  matInput
                  disabled
                  placeholder="Qty required"
                  [value]="material.reqQty"
                />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" [style.width.px]="90">
                <mat-label>Delivered</mat-label>
                <input
                  #delivered
                  matInput
                  disabled
                  placeholder="Qty delivered"
                  [value]="material.delQty"
                />
              </mat-form-field>
            </div>
            <div class="d-flex align-items-center">
              <span
                *ngIf="material.delQty === material.reqQty"
                class="material-icons green"
              >
                done
              </span>
              <span
                *ngIf="material.delQty !== material.reqQty"
                class="material-icons red"
              >
                error
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="job.timberItems?.length > 0" class="my-1">
      <app-subheader>Timber</app-subheader>

      <div class="row" *ngFor="let timber of job.timberItems">
        <div class="col-sm-6">
          <div class="d-flex h-100 align-items-center">
            <h6>
              {{ timber.item.name }}
              {{ timber.item?.projectCode }}
              <small class="text-muted">
                {{ timber.item.type }}
                {{ timber.item.uom }}
                <!-- {{ timber.item?.timberSize }} -->
                {{ timber.item?.areaPerUnit }}mm2
              </small>
            </h6>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="d-flex justify-content-around">
            <div>
              <mat-form-field appearance="outline" [style.width.px]="90">
                <mat-label>Required</mat-label>
                <input
                  matInput
                  disabled
                  placeholder="Qty required"
                  [value]="timber.reqQty"
                />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" [style.width.px]="90">
                <mat-label>Delivered</mat-label>
                <input
                  #delivered
                  matInput
                  disabled
                  placeholder="Qty delivered"
                  [value]="timber.delQty"
                />
              </mat-form-field>
            </div>
            <div class="d-flex align-items-center">
              <span
                *ngIf="timber.delQty === timber.reqQty"
                class="material-icons green"
              >
                done
              </span>
              <span
                *ngIf="timber.delQty !== timber.reqQty"
                class="material-icons red"
              >
                error
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-sm-6">
      <mat-form-field appearance="outline" *ngIf="job.instruction">
        <mat-label>Instructions</mat-label>
        <textarea
          matInput
          readonly
          cdkTextareaAutosize
          [value]="job.instruction"
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="col-sm-6">
      <mat-form-field appearance="outline" *ngIf="job.notes">
        <mat-label>Notes</mat-label>
        <textarea
          matInput
          readonly
          cdkTextareaAutosize
          [value]="job.notes"
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
