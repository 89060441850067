import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { authRoles } from '@assets/config/config';
import { UserService } from '@core/services';
import { JobService } from '@core/services/job.service';
import { Location } from '@shared/models/location.model';
import { User } from '@shared/models/user.model';

@Component({
  selector: 'app-job-search',
  templateUrl: './job-search.component.html',
  styleUrls: ['./job-search.component.scss']
})
export class JobSearchComponent implements OnInit {
  public form: UntypedFormGroup;

  public statusTypes: string[];

  public locations: Location[];

  public employees: User[] = [];

  public assignees: User[] = [];

  constructor(
    // private fb: FormBuilder,
    private dialogRef: MatDialogRef<JobSearchComponent>,
    private jobService: JobService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.form = data.form;

    // this.form = fb.group({
    //   range: fb.group({
    //     start: fb.control(null),
    //     end: fb.control(null)
    //   }),
    //   fromLocation: fb.control(null),
    //   toLocation: fb.control(null),
    //   status: fb.control(null),
    //   employee: fb.control(null),
    //   assignee: fb.control(null),
    // });
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('80%');
    this.statusTypes = this.jobService.statusTypes;
    this.getUsers();
  }

  getUsers(): void {
    this.userService.getAllUsers().subscribe(res => {
      if (res.status === 'success') {
        res.data.forEach(user => {
          if (authRoles.staff.includes(user.role)) {
            this.assignees.push(user);
          } else {
            this.employees.push(user);
          }
        });
      }
    });
  }

  onClear(): void {
    this.form.reset();
  }

  // processQuery() {
  //   // console.log(this.form.value);
  //   const queryObj = this.form.value;

  //   const fromLocation = 'fromLocation';
  //   const toLocation = 'toLocation';
  //   const employee = 'employee';
  //   const assignee = 'assignee';
  //   const status = 'status';
  //   const type = 'type[and]';
  //   const query = {};

  //   query[type] = 'delivery,pickup';

  //   if (queryObj.range?.start && queryObj.range?.start) {
  //     query['assignedDate[gt]'] = queryObj.range?.start.getTime();
  //     query['assignedDate[lt]'] = queryObj.range?.end.getTime();
  //   }

  //   if (queryObj.status) {
  //     query[status] = queryObj.status;
  //   }

  //   if (queryObj.fromLocation) {
  //     query[fromLocation] = queryObj.fromLocation._id;
  //   }

  //   if (queryObj.toLocation) {
  //     query[toLocation] = queryObj.toLocation._id;
  //   }
  //   if (queryObj.employee) {
  //     query[employee] = queryObj.employee._id;
  //   }
  //   if (queryObj.assignee) {
  //     query[assignee] = queryObj.assignee._id;
  //   }

  //   return query;
  // }
  // onSearch() {
  //   const query = this.processQuery();
  //   this.jobService.searchJobs(query).subscribe((data) => {
  //     this.dialogRef.close(data);
  //   });
  // }

  // onExport() {
  //   const query = this.processQuery();
  //   this.jobService.getCSV(query).subscribe((data) => {
  //     const link = document.createElement('a');
  //     link.href = data;
  //     link.click();

  //     this.dialogRef.close();
  //   });
  // }
}
