import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Material } from '@shared/models/material.model';
import { Observable } from 'rxjs';
import { environment } from '@env';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder
} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {
  private types: string[] = [
    'Rubber',
    'Timber',
    'Protection',
    'Glue',
    'Trims',
    'Leveling',
    'Hush',
    'Cleats',
    'Other'
  ];

  private units: string[] = [
    'Sheets',
    'Rolls',
    'Lm',
    'lengths',
    'Items',
    'Boxs',
    'Bags',
    'Drums',
    'Sausages',
    'Bottles',
    'Boards',
    'Other'
  ];

  constructor(private http: HttpClient, private fb: UntypedFormBuilder) {}

  form: UntypedFormGroup = this.getForm();

  getForm(material?: Material) {
    return this.fb.group({
      _id: new UntypedFormControl(material ? material._id : null),
      __v: new UntypedFormControl(material ? material.__v : null),
      myobCode: new UntypedFormControl(
        material ? material.myobCode : '',
        Validators.required
      ),
      name: new UntypedFormControl(material ? material.name : '', Validators.required),
      unit: new UntypedFormControl(material ? material.unit : '', Validators.required),
      type: new UntypedFormControl(material ? material.type : '', Validators.required),
      projectCode: new UntypedFormControl(material ? material.projectCode : null),
      timberSize: new UntypedFormControl(material ? material.timberSize : null),
      areaPerUnit: new UntypedFormControl(material ? material.areaPerUnit : null)
    });
  }

  resetForm() {
    this.form.reset();
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key).setErrors(null);
    });
  }

  populateForm(material: Material) {
    this.form.patchValue(material);
  }

  // get all material types
  getMaterialTypes(): string[] {
    return this.types;
  }

  // get all material units
  getMaterialUnits(): string[] {
    return this.units;
  }

  // ** HTTP methods **

  // post a material to be created in the backend
  createMaterial(material: Material): Observable<any> {
    return this.http.post(
      `${environment.API_BASE_URL}/material/create`,
      material
    );
  }

  // get material by id
  getMaterialById(id: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/material/read/${id}`);
  }

  // get all materials
  getAllMaterials(): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/material/read`);
  }

  // get all materials grouped by type
  getAllMaterialsGrouped(): Observable<any> {
    return this.http.get(
      `${environment.API_BASE_URL}/material/readGroupedMaterials`
    );
  }

  // update material
  updateMaterial(material: Material): Observable<any> {
    return this.http.put(
      `${environment.API_BASE_URL}/material/update/${material._id}`,
      material
    );
  }

  // delete user
  deleteMaterial(id: string): Observable<any> {
    return this.http.delete(
      `${environment.API_BASE_URL}/material/delete/${id}`
    );
  }
}
