<div class="row">
  <div class="col-4 d-flex flex-column justify-content-center">
    <h1>
      {{ jobItem.item?.name }}
    </h1>
    <p>
      {{ jobItem.item?.type | titlecase }}
      <span *ngIf="jobItem.item.type === 'timber'" class="subtle">
        [{{ jobItem.item.projectCode }} - {{ jobItem.item.length }}/{{
          jobItem.item.width
        }}/{{ jobItem.item.thickness }}]
      </span>
    </p>
  </div>
  <div class="col-3">
    <div class="text-center">
      <h2>
        <app-quantity
          [item]="jobItem.item"
          [quantity]="jobItem.reqQty"
        ></app-quantity>
      </h2>
      <p>Required</p>
    </div>
  </div>
  <div class="col-3">
    <div class="text-center">
      <h2>
        <app-quantity
          [item]="jobItem.item"
          [quantity]="jobItem.delQty"
        ></app-quantity>
      </h2>
      <p>Delivered</p>
    </div>
  </div>
  <div class="col-2">
    <span
      *ngIf="jobItem.delQty === jobItem.reqQty"
      class="material-icons green"
    >
      done
    </span>
    <span *ngIf="jobItem.delQty !== jobItem.reqQty" class="material-icons red">
      error
    </span>
  </div>
</div>
