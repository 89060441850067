import { NgModule } from '@angular/core';
import { GoogleMapsLoaderService } from './google-maps-loader.service';
import { GoogleMapsComponent } from './google-maps.component';
import { GoogleMapsMarkerComponent } from './google-maps-marker/google-maps-marker.component';
import { GoogleMapsAutocompleteComponent } from './google-maps-autocomplete/google-maps-autocomplete.component';

@NgModule({
  declarations: [
    GoogleMapsComponent,
    GoogleMapsMarkerComponent,
    GoogleMapsAutocompleteComponent,
  ],
  exports: [
    GoogleMapsComponent,
    GoogleMapsMarkerComponent,
    GoogleMapsAutocompleteComponent,
  ],
  imports: [],
  providers: [GoogleMapsLoaderService],
})
export class GoogleMapsModule { }
