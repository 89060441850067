<div
  id="carouselControls"
  class="carousel slide my-2"
  data-ride="carousel"
  data-wrap="false"
>
  <div class="carousel-inner">
    <div
      class="carousel-item"
      *ngFor="let elements of groupedStocks; let i = index"
      [ngClass]="{ active: i === 0 }"
    >
      <div class="row py-2">
        <div class="col-sm-4">
          <app-low-stock
            *ngIf="elements[0]"
            [stock]="elements[0]"
          ></app-low-stock>
        </div>

        <div class="col-sm-4">
          <app-low-stock
            *ngIf="elements[1]"
            [stock]="elements[1]"
          ></app-low-stock>
        </div>

        <div class="col-sm-4">
          <app-low-stock
            *ngIf="elements[2]"
            [stock]="elements[2]"
          ></app-low-stock>
        </div>
      </div>
    </div>
  </div>
  <a
    class="carousel-control-prev"
    href="#carouselControls"
    role="button"
    data-slide="prev"
    *ngIf="groupedStocks.length > 1"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a
    class="carousel-control-next"
    href="#carouselControls"
    role="button"
    data-slide="next"
    *ngIf="groupedStocks.length > 1"
  >
    <!-- *ngIf="groupedStocks.length > 1" -->
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
