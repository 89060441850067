<ng-container *ngIf="item.areaPerUnit; else default">
  <sup>
    {{ quantity }}
    {{ item.uom }}<span *ngIf="quantity !== 1">s</span>
  </sup>
  /
  <sub *ngIf="item.areaPerUnit">
    {{ multiply(quantity, item.areaPerUnit) }}
    m<sup>2</sup>
  </sub>
</ng-container>

<ng-template #default>
  <p>
    {{ quantity }}
    {{ item.uom }}<span *ngIf="quantity !== 1">s</span>
  </p>
</ng-template>
