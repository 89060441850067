import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '@core/services/user.service';
import { User } from '@shared/models/user.model';

@Component({
  selector: 'app-sign-in',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: UntypedFormGroup;
  public loading = false;
  public showUnsuccessfulAlert = false;
  public showSuccessfulAlert = false;
  public error: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // check if user is already logged in
    if (this.userService.isLoggedIn()) {
      this.router.navigateByUrl('/');
    }

    // initialise the form group
    this.loginForm = this.formBuilder.group({
      email: [''],
      password: ['']
    });
  }

  // convenience getter for easy access to form fields
  get form() {
    return this.loginForm.controls;
  }

  // on form submission
  onSubmit(): void {
    let user = new User();
    user = this.loginForm.value;
    this.loading = true;
    this.loginForm.disable();

    this.userService.loginUser(user).subscribe(
      (res: { token: string; data: User }) => {
        this.showSuccessfulAlert = true;
        this.userService.setToken(res.token);
        this.userService.setRole(res.data.role);
        this.userService.setId(res.data._id);
        this.router.navigate(['/']);
        this.showSuccessfulAlert = false;
      },
      err => {
        this.loading = false;
        this.error = err.error.message;
        this.showUnsuccessfulAlert = true;
        this.loginForm.get('password').reset();
        this.loginForm.enable();

        setTimeout(() => {
          this.showUnsuccessfulAlert = false;
        }, 3000);
      }
    );
  }
}
