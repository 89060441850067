<div class="row py-2">
  <div class="col-2">
    <div class="d-flex h-100 align-items-center">
      <span *ngIf="job.status === 'active'" class="material-icons green">
        assignment
      </span>
      <span *ngIf="job.status === 'review'" class="material-icons red">
        done
      </span>
    </div>
  </div>
  <div class="col-3">
    <div class="d-flex h-100 align-items-center">
      <app-display title="Time" [props]="[job.assignedTime]"></app-display>
    </div>
  </div>
  <div class="col-7">
    <div class="d-flex h-100 align-items-center">
      <app-display
        [title]="'To - ' + location.name"
        [props]="[
          location.address.streetNumber,
          location.address.streetName,
          location.address.suburb
        ]"
      ></app-display>
    </div>
  </div>
</div>
