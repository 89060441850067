import { Component, Input, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
})
export class DisplayComponent implements OnInit {
  @Input() title: string;

  @Input() props: string[];

  @Input() error = false;

  constructor(private titlecasePipe: TitleCasePipe) { }

  ngOnInit(): void { }

  render(prop) {
    if (!prop && prop !== 0) {
      return;
      // return 'None';
    }

    if (typeof prop === 'string') {
      if (prop.match(/^\d/)) {
        return prop;
      }
      return this.titlecasePipe.transform(prop);
    }
    return prop;
  }
}
