<form class="example-form">
  <mat-form-field appearance="outline" class="example-full-width">
    <input
      type="text"
      [placeholder]="placeholder"
      aria-label="Number"
      matInput
      (keyup)="onKeyup($event.target.value)"
      [formControl]="control"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of options" [value]="option">
        <span class="material-icons px-2">
          {{ getIcon(option.type) }}
        </span>
        <span>{{ option.name | titlecase }}</span> |
        <small
          >Address: {{ option.address.streetNumber }},
          {{ option.address.streetName }}, {{ option.address.suburb }}</small
        >
      </mat-option>
    </mat-autocomplete>
    <mat-error
      *ngIf="control.hasError('required') && (control.dirty || control.touched)"
      >Required</mat-error
    >
  </mat-form-field>
</form>
