import { Injectable, ElementRef } from '@angular/core';
import { environment } from '@env';
import { Loader } from '@googlemaps/js-api-loader';
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  from,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  private loader: Loader;

  private isLoaded = new BehaviorSubject<boolean>(false);

  public isLoaded$ = this.isLoaded.asObservable();

  private googleMapsInstance = new Subject();

  public googleMapsInstance$ = this.googleMapsInstance.asObservable();

  private googleMap: Subject<google.maps.Map> = new Subject();

  public googleMap$: Observable<google.maps.Map> =
    this.googleMap.asObservable();

  private autoComplete: Subject<google.maps.places.Autocomplete> =
    new Subject();

  public autoComplete$: Observable<google.maps.places.Autocomplete> =
    this.initAutocomplete$();

  private placeResult: Subject<google.maps.places.PlaceResult> = new Subject();

  public placeResult$: Observable<google.maps.places.PlaceResult> =
    this.initPlaceResult$();

  constructor() {
    this.loader = new Loader({
      apiKey: 'AIzaSyCtK0lT9OnD4jHYDRQ_7OLQc3eicFNFoJY',
      version: 'weekly',
      libraries: ['places'],
    });
    this.loader.load().then(() => {
      this.isLoaded.next(true);
      this.googleMapsInstance.next(google.maps);
    });
  }

  getLoader(): Loader {
    return this.loader;
  }

  loadMap(
    mapRef: ElementRef,
    options: google.maps.MapOptions
  ): Observable<void> {
    return this.isLoaded$.pipe(
      filter((x) => !!x),
      map(() => {
        this.googleMap.next(new google.maps.Map(mapRef.nativeElement, options));
      })
    );
  }

  private initPlaceResult$() {
    return this.placeResult
      .asObservable()
      .pipe(tap((place) => console.log(place)));
  }

  private initAutocomplete$() {
    return this.autoComplete.asObservable().pipe(
      tap((autocomplete) => {
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();

          this.placeResult.next(place);
        });
      })
    );
  }

  loadAutoComplete(searchRef: ElementRef): Observable<void> {
    return this.isLoaded$.pipe(
      filter((x) => !!x),
      map(() => {
        this.autoComplete.next(
          new google.maps.places.Autocomplete(searchRef.nativeElement, {
            types: ['address'],
            componentRestrictions: {
              country: environment.COUNTRY_SEARCH_RESTRICTION,
            },
          })
        );
      })
    );
  }
}
