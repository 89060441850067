<div class="row">
  <div class="col-lg-5">
    <app-item-search
      id="item-search"
      class="w-100"
      [control]="jobItem.get('item')"
      [type]="timber ? 'timber' : '-timber'"
    >
    </app-item-search>
  </div>

  <div class="col-lg-1 col-3">
    <mat-form-field appearance="outline" [style.width.px]="75">
      <mat-label>Qty</mat-label>
      <input
        matInput
        class="d-inline-block"
        matAutosize="true"
        placeholder="Qty"
        autocomplete="off"
        [formControl]="jobItem.get('reqQty')"
        min="0"
        oninput="this.value = Math.abs(this.value)"
        type="number"
      />

      <!-- (input)="checkQty(i, 'material')" -->
      <mat-error *ngIf="jobItem.get('reqQty').hasError('noStock')"
        >No stock</mat-error
      >
    </mat-form-field>
  </div>

  <div class="col-lg-2 col-3 text-center">
    <h2>
      <app-quantity
        *ngIf="jobItem.get('item').value"
        [item]="jobItem.get('item').value"
        [quantity]="jobItem.get('reqQty').value"
      ></app-quantity>
    </h2>
    <p>Requested</p>
  </div>
  <div class="col-lg-2 col-3 text-center">
    <h2>
      <app-quantity
        *ngIf="jobItem.get('item').value"
        [item]="jobItem.get('item').value"
        [quantity]="stock ? stock.curQuantity + stock.penQuantity : '0'"
      ></app-quantity>
    </h2>
    <p>Stock EST</p>
  </div>
  <div class="col-lg-1 col-3">
    <mat-form-field appearance="outline" [style.width.px]="75" *ngIf="job">
      <mat-label>Sent</mat-label>
      <input
        matInput
        class="d-inline-block"
        matAutosize="true"
        placeholder="Quantity"
        autocomplete="off"
        [formControl]="jobItem.get('delQty')"
        type="number"
        min="0"
        oninput="this.value = Math.abs(this.value)"
      />
    </mat-form-field>
  </div>
  <div class="col-lg-1 col-3">
    <div class="mt-3">
      <button mat-mini-fab color="warn" (click)="onRemove()">
        <span class="material-icons"> delete </span>
      </button>
    </div>
  </div>
</div>
