import { Component, OnInit } from '@angular/core';
import { Material } from '@shared/models/material.model';
import { MaterialService } from '@core/services/material.service';

@Component({
  selector: 'app-add-material',
  templateUrl: './add-material.component.html',
  styleUrls: ['./add-material.component.scss'],
  providers: [MaterialService]
})
export class AddMaterialComponent implements OnInit {
  public material: Material = new Material();

  constructor(private materialService: MaterialService) {}

  ngOnInit() {
    this.material = new Material();
  }

  onSave() {
    this.materialService.createMaterial(this.material).subscribe(data => {
      console.log(data);
    });
  }
}
