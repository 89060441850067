import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { GoogleMapsModule } from '@modules/google-maps/google-maps.module';
import { LogSearchComponent } from './components/log-search/log-search.component';
import { JobFormItemComponent } from './components/job-form-item/job-form-item.component';
import { InputComponent } from './components/input/input.component';
import * as fromComponents from './components';
import { JobExportComponent } from './components/job-export/job-export.component';
import { AuthHideDirective } from './directives/auth-hide.directive';
import { AuthDisableDirective } from './directives/auth-disable.directive';

// shared module is used to declare common components, pipes and directives
// do not import this module into the appmodule file
@NgModule({
  declarations: [
    ...fromComponents.components,
    LogSearchComponent,
    JobFormItemComponent,
    InputComponent,
    JobExportComponent,
    AuthHideDirective,
    AuthDisableDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatExpansionModule,
    MatCardModule,
    MatSlideToggleModule,
    GoogleMapsModule,
  ],
  exports: [
    FormsModule,
    AuthHideDirective,
    AuthDisableDirective,
    ...fromComponents.components,
  ],
})
export class SharedModule { }
