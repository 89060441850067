import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@core/services';
import { LogService } from '@core/services/log.service';
import { User } from '@shared/models/user.model';

@Component({
  selector: 'app-log-search',
  templateUrl: './log-search.component.html',
  styleUrls: ['./log-search.component.scss']
})
export class LogSearchComponent implements OnInit {
  public form: UntypedFormGroup;

  public users: User[] = [];

  constructor(
    private dialogRef: MatDialogRef<LogSearchComponent>,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    this.form = this.logService.query;
  }

  onClear() {
    this.form.reset();
  }

  onSearch() {
    this.logService.searchLogs().subscribe(
      data => {
        this.dialogRef.close(data);
      },
      error => {
        console.log(error);
      }
    );
  }

  onExport() {
    this.logService.getCSV().subscribe(
      data => {
        const link = document.createElement('a');
        link.href = data;
        link.click();

        this.dialogRef.close(data);
      },
      error => {
        console.log(error);
      }
    );
  }
}
