import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { environment } from '@env';
import { Log } from '@shared/models/log.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  query = this.fb.group({
    start: this.fb.control(undefined),
    end: this.fb.control(undefined)
  });

  constructor(private http: HttpClient, private fb: UntypedFormBuilder) {}

  form: UntypedFormGroup = this.getForm();

  getForm(log?: Log) {
    return this.fb.group({
      _id: new UntypedFormControl(log ? log._id : null),
      date: new UntypedFormControl(log ? log.date : new Date(), Validators.required),
      startTime: new UntypedFormControl(log ? log.startTime : '', Validators.required),
      endTime: new UntypedFormControl(log ? log.endTime : '', Validators.required),
      breakDuration: new UntypedFormControl(
        log ? log.breakDuration : null,
        Validators.required
      ),
      workDuration: new UntypedFormControl(
        log ? log.workDuration : 0,
        Validators.required
      ),
      notes: new UntypedFormControl(log ? log.notes : '')
    });
  }

  processQuery() {
    const queryObj = this.query.value;

    if (queryObj.start && queryObj.end) {
      queryObj['date[gte]'] = queryObj.start.getTime();
      queryObj['date[lte]'] = queryObj.end.getTime();
      delete queryObj.start;
      delete queryObj.end;
    } else {
      delete queryObj.start;
      delete queryObj.end;
    }

    return queryObj;
  }

  // search jobs with a query object
  searchLogs(): Observable<any> {
    // console.log(this.form.value);
    const query = this.processQuery();

    return this.http.get(`${environment.API_BASE_URL}/v1/logs`, {
      params: query
    });
  }

  // get all jobs
  getCSV(): Observable<any> {
    const query = this.processQuery();

    return this.http
      .get(`${environment.API_BASE_URL}/v1/logs/csv`, { params: query })
      .pipe(
        map(res => {
          return `${environment.BASE_URL}${res}`;
        })
      );
  }

  resetForm() {
    this.form.reset();
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key).setErrors(null);
    });
  }

  populateForm(log: Log) {
    this.form.patchValue(log);
  }

  // ** HTTP methods **

  // post a log to be created in the backend
  createLog(log: Log): Observable<any> {
    return this.http.post(`${environment.API_BASE_URL}/v1/logs`, log);
  }

  // get log by id
  getLogById(id: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/logs/${id}`);
  }

  // get all logs
  getAllLogs(): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/logs`);
  }

  // get all logs
  getLogsByUserId(userId): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/logs`, {
      params: { user: userId }
    });
  }

  // update log
  updateLog(log: Log): Observable<any> {
    return this.http.patch(
      `${environment.API_BASE_URL}/v1/logs/${log._id}`,
      log
    );
  }

  // delete log
  deleteLog(id: string): Observable<any> {
    return this.http.delete(`${environment.API_BASE_URL}/v1/logs/${id}`);
  }
}
