export class User {
  // tslint:disable-next-line:variable-name
  _id: string;

  firstName: string;

  lastName: string;

  email: string;

  password: string;

  passwordConfirm: string;

  role: string;
}
