<h6 [class]="error ? 'red' : 'green'">
  {{ title | titlecase }}
  <div>
    <ng-container *ngIf="props; else elseBlock">
      <span *ngFor="let prop of props" class="text-dark">
        {{ render(prop) }}
      </span>
    </ng-container>

    <ng-template #elseBlock>
      <ng-content></ng-content>
    </ng-template>
  </div>
</h6>
