import { Component, Input, OnInit } from '@angular/core';
import { Item } from '@shared/models/item.model';
import { Stock } from '@shared/models/stock.model';

@Component({
  selector: 'app-quantity',
  templateUrl: './quantity.component.html',
  styleUrls: ['./quantity.component.scss'],
})
export class QuantityComponent implements OnInit {
  @Input() quantity: number;

  @Input() item: Item;

  constructor() { }

  ngOnInit(): void { }

  multiply = (num1: number, num2: number) =>
    Math.round(num1 * num2 * 100) / 100;
}
