<form class="example-form">
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      type="text"
      [placeholder]="helperString"
      aria-label="Number"
      matInput
      (keyup)="onKeyup($event.target.value)"
      [formControl]="control"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of options" [value]="option">
        <!-- <span class="material-icons px-2">
          {{ getIcon(option.type) }}
        </span> -->
        <small>
          {{ displayFn(option) }}
        </small>
      </mat-option>
    </mat-autocomplete>
    <mat-error
      *ngIf="control.hasError('required') && (control.dirty || control.touched)"
      >Required</mat-error
    >
  </mat-form-field>
</form>
