import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Vehicle } from '@shared/models/vehicle.model';
import { Observable, of } from 'rxjs';
import { environment } from '@env';
import { map, catchError } from 'rxjs/operators';
import { CrudService, Response } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class VehicleService extends CrudService<Vehicle> {
  constructor(protected http: HttpClient, private fb: UntypedFormBuilder) {
    super(http, 'vehicles');
  }

  form: UntypedFormGroup = this.getForm();

  getForm(vehicle?: Vehicle): UntypedFormGroup {
    return this.fb.group({
      _id: new UntypedFormControl(vehicle ? vehicle._id : null),
      make: new UntypedFormControl(
        vehicle ? vehicle.make : '',
        Validators.required
      ),
      model: new UntypedFormControl(
        vehicle ? vehicle.model : '',
        Validators.required
      ),
      colour: new UntypedFormControl(
        vehicle ? vehicle.colour : '',
        Validators.required
      ),
    });
  }

  resetForm(): void {
    this.form.reset();
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setErrors(null);
    });
  }

  populateForm(vehicle: Vehicle): void {
    this.form.patchValue(vehicle);
  }

  // ** HTTP methods **

  // post a vehicle to be created in the backend
  createVehicle(vehicle: Vehicle): Observable<any> {
    const url = `${environment.API_BASE_URL}/v1/vehicles`;
    return this.http.post(url, vehicle).pipe(
      map((res: any) => {
        if (res.status === 'success') {
          return res.data;
        }
      }),
      catchError(this.handleError<Vehicle>('createVehicle'))
    );
  }

  // search for vehicles
  search(val, limit: string): Observable<any> {
    return this.http.get(
      `${environment.API_BASE_URL}/v1/vehicles/search/${val}`,
      {
        params: { limit },
      }
    );
  }

  // get all vehicles
  getAllVehicles = (): Observable<Vehicle[] | null> => {
    return this.readAll().pipe(
      map((res: Response<Vehicle[]>) =>
        res.status === 'success' ? res.data : null
      )
    );
  };

  // Get all inactive vehicles (deleted)
  getInactiveVehicles = (): Observable<Vehicle[] | null> => {
    return this.readAll({ deleted: 'true' }).pipe(
      map((res: Response<Vehicle[]>) =>
        res.status === 'success' ? res.data : null
      )
    );
  };

  // update vehicle
  updateVehicle(vehicle: Vehicle): Observable<any> {
    return this.http.patch(
      `${environment.API_BASE_URL}/v1/vehicles/${vehicle._id}`,
      vehicle
    );
  }

  // delete vehicle
  deleteVehicle(id: string): Observable<any> {
    return this.http.delete(`${environment.API_BASE_URL}/v1/vehicles/${id}`);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
