import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LocationService } from '@core/services/location.service';
import { Location } from '@shared/models/location.model';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss'],
})
export class LocationSearchComponent implements OnInit {
  options: Location[] = [];

  timeout = null; // default set

  @Input() placeholder = 'Location';

  @Output() emitter = new EventEmitter();

  @Input() control = new UntypedFormControl();

  constructor(private locationService: LocationService) {}

  ngOnInit(): void {
    this.control.valueChanges.subscribe((val) => {
      if (typeof val === 'object') {
        this.emitter.emit(val);
      }
    });
  }

  onKeyup(val): void {
    const _this = this;
    // this.locationService;
    // If it has already been set cleared first.
    clearTimeout(this.timeout);

    // set timeout to 1000ms
    this.timeout = setTimeout(function () {
      // call a search to backend for locations
      _this.search(val);
    }, 1000);
  }

  search(locationName: string): void {
    if (locationName) {
      this.locationService.search(locationName, '10').subscribe((res) => {
        if (res.status === 'success') {
          this.options = res.data;
        }
      });
    }
  }

  getIcon(type: string): string {
    switch (type) {
      case 'warehouse':
        return 'store';
      case 'supplier':
        return 'archive';
      case 'client':
        return 'unarchive';
      default:
        return 'store';
    }
  }

  displayFn(location: Location): string {
    if (location && location.name) {
      let display = location.name;
      display += ` | ${location.address.streetNumber}`;
      display += `, ${location.address.streetName}`;
      display += ` ${location.address.suburb}`;

      return display;
    }
    return '';
  }
}
