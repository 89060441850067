import { Component, OnInit } from '@angular/core';
import { VehicleService } from '@core/services/vehicle.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Vehicle } from '@shared/models/vehicle.model';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss']
})
export class VehicleFormComponent implements OnInit {
  constructor(
    private vehicleService: VehicleService,
    public dialogRef: MatDialogRef<VehicleFormComponent>
  ) {}

  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(() => {
      this.onClear();
    });
  }

  get form(): UntypedFormGroup {
    return this.vehicleService.form;
  }

  get id(): string {
    return this.vehicleService.form.get('_id').value;
  }

  onClear(): void {
    this.vehicleService.resetForm();
  }

  onSubmit(): void {
    const vehicle = this.vehicleService.form.value as Vehicle;

    if (this.vehicleService.form.valid) {
      if (!this.vehicleService.form.get('_id').value) {
        this.vehicleService.createVehicle(vehicle).subscribe(res => {
          this.dialogRef.close(res);
        });
      } else {
        this.vehicleService.updateVehicle(vehicle).subscribe(res => {
          this.dialogRef.close(res);
        });
      }
    } else {
      this.dialogRef.close({ status: false });
    }
  }
}
