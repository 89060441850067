import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { Location } from '@shared/models/location.model';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { Params } from '@angular/router';
import { CrudService } from './crud.service';

export interface AddressForm {
  streetNumber: string;
  streetName: string;
  suburb: string;
  state: string;
  postCode: string;
  lat: number;
  long: number;
  placeId: string;
}

export interface AddressFormGroup extends FormGroup {
  controls: {
    streetNumber: FormControl;
    streetName: FormControl;
    suburb: FormControl;
    state: FormControl;
    postCode: FormControl;
    lat: FormControl;
    long: FormControl;
    placeId: FormControl;
  };
}

@Injectable({
  providedIn: 'root',
})
export class LocationService extends CrudService<Location> {
  constructor(protected http: HttpClient, private fb: UntypedFormBuilder) {
    super(http, 'locations');
    this.getWarehouse().subscribe((res) => {
      this.warehouse = res.data[0];
    });
  }

  warehouse: Location;

  getForm = (location?: Location): FormGroup => {
    const addressForm = this.fb.group({
      streetNumber: new FormControl('', Validators.required),
      streetName: new FormControl('', Validators.required),
      suburb: new FormControl(''),
      state: new FormControl(''),
      postCode: new FormControl(''),
      lat: new FormControl(''),
      long: new FormControl(''),
      placeId: new FormControl(''),
    }) as AddressFormGroup;

    return this.fb.group({
      _id: new UntypedFormControl(location ? location._id : undefined),
      name: new UntypedFormControl(
        location ? location.name : '',
        Validators.required
      ),
      ref: new UntypedFormControl(location ? location.ref : null),
      type: new UntypedFormControl(location ? location.type : null, [
        Validators.required,
      ]),
      address: addressForm,
      warehouse: new UntypedFormControl(location ? location.warehouse : false),
      manual: new UntypedFormControl(false),
      createdAt: new UntypedFormControl(location ? location.createdAt : null),
      updatedAt: new UntypedFormControl(location ? location.updatedAt : null),
    });
  };

  form: UntypedFormGroup = this.getForm();

  resetForm = (): void => {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setErrors(null);
    });
    this.form.reset();
  };

  populateForm = (location: Location): void => {
    this.form.patchValue(location);
  };

  // ** HTTP methods **

  // post a location to be created in the backend
  createLocation(location: Location): Observable<any> {
    return this.http.post(`${environment.API_BASE_URL}/v1/locations`, location);
  }

  // get location by id
  getLocationById(id: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/locations/${id}`);
  }

  // get warehouse location
  getWarehouse(): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/locations`, {
      params: { warehouse: 'true' },
    });
  }

  // search for locations
  search(val, limit: string): Observable<any> {
    return this.http.get(
      `${environment.API_BASE_URL}/v1/locations/search/${val}`,
      {
        params: { limit },
      }
    );
  }

  // get all locations
  getAllLocations(params?: Params): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/locations`, {
      params: { limit: '300', ...params },
    });
  }

  // update location
  updateLocation(location: Location): Observable<any> {
    return this.http.patch(
      `${environment.API_BASE_URL}/v1/locations/${location._id}`,
      location
    );
  }

  // delete location
  deleteLocation(id: string): Observable<any> {
    return this.http.delete(`${environment.API_BASE_URL}/v1/locations/${id}`);
  }
}
