import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Item } from '@shared/models/item.model';
import { Observable } from 'rxjs';
import { environment } from '@env';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  // TODO get these from service
  private types: string[] = [
    'glue',
    'underlay',
    'protection',
    'cleat',
    'timber',
    'consumable',
    'trim',
    'tape',
    'leveling',
    'other',
  ];

  private units: string[] = [
    'sheet',
    'roll',
    'lm',
    'pack',
    'length',
    'bag',
    'drum',
    'sausage',
    'bottle',
    'board',
    'item',
  ];

  constructor(private http: HttpClient, private fb: UntypedFormBuilder) { }

  form: UntypedFormGroup = this.getForm();

  getForm(item?: Item) {
    return this.fb.group({
      _id: new UntypedFormControl(item ? item._id : null),
      myobCode: new UntypedFormControl(
        item ? item.myobCode : '',
        Validators.required
      ),
      name: new UntypedFormControl(item ? item.name : '', Validators.required),
      uom: new UntypedFormControl(item ? item.uom : '', Validators.required),
      type: new UntypedFormControl(item ? item.type : '', Validators.required),
      minStock: new UntypedFormControl(item ? item.minStock : null),
      projectCode: new UntypedFormControl(item ? item.projectCode : null),
      length: new UntypedFormControl(item ? item.length : null),
      width: new UntypedFormControl(item ? item.width : null),
      thickness: new UntypedFormControl(item ? item.thickness : null),
      areaPerUnit: new UntypedFormControl(item ? item.areaPerUnit : null),
      description: new UntypedFormControl(item ? item.description : null),
    });
  }

  resetForm() {
    this.form.reset();
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setErrors(null);
    });
  }

  populateForm = (item: Item): void => this.form.patchValue(item);

  // get all item types
  getItemTypes = (): string[] => this.types;

  // get all item units
  getItemUnits(): string[] {
    return this.units;
  }

  // get item by MYOB code
  getItemByMyobCode(myobCode: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/items`, {
      params: { myobCode },
    });
  }

  // ** HTTP methods **

  // post a item to be created in the backend
  createItem(item: Item): Observable<any> {
    return this.http.post(`${environment.API_BASE_URL}/v1/items`, item);
  }

  // get item by id
  getItemById(id: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/items/${id}`);
  }

  // get all items
  getAllItems(): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/items`);
  }

  // get all timber items
  getAllTimberItems(params?: Params): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/items/?type=timber`, {
      params: { limit: '300', ...params },
    });
  }

  // get all material items
  getAllMaterialItems(params?: Params): Observable<any> {
    return this.http.get(
      `${environment.API_BASE_URL}/v1/items/?type[ne]=timber`,
      {
        params: { limit: '300', ...params },
      }
    );
  }

  // search through items
  search(val, type?): Observable<any> {
    if (type) {
      let params;
      if (type === '-timber') {
        params = {
          limit: '10',
          'type[ne]': 'timber',
        };
      } else {
        params = {
          limit: '10',
          type: 'timber',
        };
      }

      return this.http.get(
        `${environment.API_BASE_URL}/v1/items/search/${val}`,
        {
          params,
        }
      );
    }
    return this.http.get(`${environment.API_BASE_URL}/v1/items/search/${val}`, {
      params: { limit: '10' },
    });
  }

  // update item
  updateItem(item: Item): Observable<any> {
    return this.http.patch(
      `${environment.API_BASE_URL}/v1/items/${item._id}`,
      item
    );
  }

  // delete item
  deleteItem(id: string): Observable<any> {
    return this.http.delete(`${environment.API_BASE_URL}/v1/items/${id}`);
  }
}
