import { Component, OnDestroy, OnInit } from '@angular/core';
import { JobService } from '@core/services/job.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Job } from '@shared/models/job.model';
import { UntypedFormArray } from '@angular/forms';
import { Contact } from '@shared/models/contact.model';
import { Stock } from '@shared/models/stock.model';
import { User } from '@shared/models/user.model';
import { ContactService } from '@core/services/contact.service';
import { StockService } from '@core/services/stock.service';
import { UserService } from '@core/services';
import { VehicleService } from '@core/services/vehicle.service';
import { Vehicle } from '@shared/models/vehicle.model';
import { LocationFormComponent } from '@shared/components/location-form/location-form.component';
import { ContactFormComponent } from '@shared/components/contact-form/contact-form.component';
import { Location } from '@shared/models/location.model';
import { Item } from '@shared/models/item.model';
import { Observable, Subscription } from 'rxjs';
import { LocationService } from '@core/services/location.service';
import { authRoles } from '@assets/config/config';
import { SnackbarService } from '@core/services/snackbar.service';

interface JobItem {
  item: Item;
  delQty: number;
  reqQty: number;
}

@Component({
  selector: 'app-job-form',
  templateUrl: './job-form.component.html',
  styleUrls: ['./job-form.component.scss'],
})
export class JobFormComponent implements OnInit, OnDestroy {
  constructor(
    private jobService: JobService,
    private contactService: ContactService,
    private vehicleService: VehicleService,
    private stockService: StockService,
    private locationService: LocationService,
    private userService: UserService,
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<JobFormComponent>
  ) {}

  userId: string;

  materialStocks: Stock[] = [];

  timberStocks: Stock[] = [];

  contacts: Contact[];

  materials: Item[] = [];

  timbers: Item[] = [];

  vehicles: Vehicle[];

  users: User[];

  assignees: User[];

  employeeList: User[];

  prevLocation = '';

  prevItemId = '';

  types: string[] = ['delivery', 'pickup'];

  warehouse: Location;

  initialJob: Job;

  timberStockSubscription: Subscription = new Subscription();

  materialStockSubscription: Subscription = new Subscription();

  typeSubscription: Subscription = new Subscription();

  pickupSubscription: Subscription = new Subscription();

  public statusTypes: string[] = ['draft', 'active', 'review', 'complete'];

  ngOnInit(): void {
    this.dialogRef.updateSize('100%');
    this.getUsers();
    this.getContacts();
    this.getVehicles();
    this.getWarehouse();
    // if the job is pre-existing, fill the stock arrays
    this.initExisting();
    this.typeSubscription = this.getTypeSubscription();

    // if job has been exported
    if (this.immutable.value) {
      this.form.disable();
    }
  }

  ngOnDestroy(): void {
    this.typeSubscription.unsubscribe();
    this.materialStockSubscription.unsubscribe();
    this.timberStockSubscription.unsubscribe();
    this.pickupSubscription.unsubscribe();
  }

  getWarehouse() {
    this.warehouse = this.locationService.warehouse;
  }

  getTypeSubscription() {
    // if type is the delivery must the from location be the warehouse?
    // if type is pickup does the to location always have to be the warehouse?

    // when the type is a pickup, and the from location is a getSupplier
    // then the stock must show the warehouse

    // when the job is a pickup and the from location is a client, should the stock display the warehouse?
    // or the client stock levels

    // if the job is a delivery, add the warehouse as the from location, else make the warehouse the to locationService
    // stock tracks the warehouse
    return this.type.valueChanges.subscribe((type) => {
      console.log('changing', type);
      this.subscriber(type);
      // add the warehouse depending on the job type
      // A is the location of the warehouse, B is the opposite

      // const warehouseLocationSuffix = type === 'delivery' ? 'from' : 'to';
      // const oppositeLocationSuffix = type !== 'delivery' ? 'from' : 'to';

      // if (this[oppositeLocationSuffix + 'Location'].value === this.warehouse) {
      //   this[oppositeLocationSuffix + 'Location'].reset();
      // }

      // this[warehouseLocationSuffix + 'Location'].setValue(this.warehouse);
    });
  }

  subscriber(type: string): void {
    this.materialStockSubscription.unsubscribe();
    this.timberStockSubscription.unsubscribe();
    this.pickupSubscription.unsubscribe();

    if (type === 'delivery') {
      if (this.toLocation.value === this.warehouse) {
        this.toLocation.reset();
        this.toLocation.enable();
      }

      this.fromLocation.setValue(this.warehouse);
      this.fromLocation.disable();

      this.materialStockSubscription.unsubscribe();
      this.timberStockSubscription.unsubscribe();

      this.materialStockSubscription = this.stockSubscription(
        'material',
        'from'
      );
      this.timberStockSubscription = this.stockSubscription('timber', 'from');
    } else if (type === 'pickup') {
      const fromLoc = this.fromLocation.value;
      if (fromLoc === this.warehouse) {
        this.fromLocation.reset();
        this.fromLocation.enable();
      }
      this.toLocation.setValue(this.warehouse);
      this.toLocation.disable();

      // from location isnt selected yet

      // subscribe to the from location then make call accordingly?
      // how to manage subscriptions?

      // AT THE MOMENT THIS ALWAYS USES THE WAREHOUSE AS STOCK LOCATION
      this.pickupSubscription = this.fromLocation.valueChanges.subscribe(
        (location) => {
          if (location && location._id) {
            if (location.type === 'client') {
              // display stock at the client (fromLocation)

              this.materialStockSubscription = this.stockSubscription(
                'material',
                'from'
              );
              this.timberStockSubscription = this.stockSubscription(
                'timber',
                'from'
              );
            } else if (location.type === 'supplier') {
              // display stock at the warehouse (toLocation)
              this.materialStockSubscription = this.stockSubscription(
                'material',
                'to'
              );
              this.timberStockSubscription = this.stockSubscription(
                'timber',
                'to'
              );
            }
          }
        }
      );
    }
  }

  stockSubscription(itemType, locationSuffix) {
    // console.log('subscribing');

    // subscribes to job items and and calls gets stock per location
    // must subscribe to the location also, incase  it changes on a pickup

    // const location$: Observable<Location> = this[locationSuffix + 'Location'].valueChanges;
    const item$: Observable<[JobItem]> = this[`${itemType}Items`].valueChanges;

    return item$.subscribe((items) => {
      for (const [i, val] of items.entries()) {
        if (val.item?._id) {
          // const locationId: string = this[locationSuffix + 'Location'].value._id;
          const { item } = val;
          // console.log(item);

          // const locationChanged = locationId !== this.prevLocation;
          const itemChanged = item._id !== this.prevItemId;

          if (itemChanged) {
            this.prevItemId = item._id;
            this.getStockItems(item, i);
          }
        }
      }
    });

    // return combineLatest([location$, item$])
    //   .subscribe(([location, items]) => {
    //     console.log(location, items);

    //     if (location && location._id) {
    //       for (const [i, val] of items.entries()) {
    //         if (val.item?._id) {
    //           const locationId: string = this[locationSuffix + 'Location'].value._id;
    //           const item = val.item;

    //           const locationChanged = locationId !== this.prevLocation;
    //           const itemChanged = item._id !== this.prevItemId;
    //           console.log('here');

    //           if ((locationId && locationChanged) || itemChanged) {
    //             this.prevLocation = locationId;
    //             this.prevItemId = item._id;

    //             console.log('her');

    //             this.getStockItems(item, i, locationId);
    //           }
    //         }
    //       }
    //     }
    //   });
  }

  initExisting() {
    this.userId = this.userService.getId();

    const job = this.form.value;
    if (job._id) {
      // save initial job to track previous stock quantitys
      this.initialJob = job;

      // disable the locations on a previously saved job to simplify stock calculation
      this.form.controls.fromLocation.disable();
      this.form.controls.toLocation.disable();
      this.form.controls.type.disable();

      // re-emit from location to start the combinelatest subscriptions
      // this.form.get('fromLocation').setValue(job.fromLocation);
      // this.form.get('toLocation').setValue(job.toLocation);
      // this.form.get('type').setValue(job.type);

      // this.form.get('fromLocation').updateValueAndValidity({ onlySelf: true, emitEvent: true });
      // this.form.get('toLocation').updateValueAndValidity({ onlySelf: true, emitEvent: true });
      // this.form.get('type').setValue(job.type);

      this.subscriber(job.type);

      // get stocks for items
      job.materialItems.forEach((element, i) => {
        this.getStockItems(element.item, i);
      });

      job.timberItems.forEach((element, i) => {
        this.getStockItems(element.item, i);
      });
    } else if (job.fromLocation?._id) {
      // if just the location exists, add the relevent stocks
      this.form.get('fromLocation').setValue(job.fromLocation);
      job.materialItems.forEach((element, i) => {
        this.getStockItems(element.item, i);
      });

      job.timberItems.forEach((element, i) => {
        this.getStockItems(element.item, i);
      });
    }
  }

  // finds the stock for an item at the from location and adds it to
  // a stock array that corresponds to the index of the items form array
  getStockItems(item, index) {
    // get the stock at the from location
    this.stockService
      .getStockByQuery({
        item: item._id,
        location: this.warehouse._id,
      })
      .subscribe((res) => {
        if (item.type === 'timber') {
          this.timberStocks[index] = res.data[0];
          // this.checkQty(index, 'material');
        } else {
          this.materialStocks[index] = res.data[0];
          // this.checkQty(index, 'timber');
        }
      });
  }

  // check stocks
  checkStocks() {
    const job = this.form.getRawValue();
    // const fromLocation = this.form.get('fromLocation').value;

    // check if job already exists
    // if it exists the est stock will already have accounted for this item, so dont
    // use it in calc (add it onto the est stock if new)

    let timberCheck = true;
    let materialCheck = true;

    job.materialItems.forEach((el, i) => {
      const stockObj = this.materialStocks[i];

      let stockQty;
      if (
        job._id &&
        this.initialJob.status !== 'draft' &&
        this.initialJob.materialItems[i]
      ) {
        // need to add the initial stock amount, not the current. Only if the previous job wasnt a draft
        console.log(this.initialJob.materialItems[i].reqQty);

        const initialQty = this.initialJob.materialItems[i].reqQty;
        stockQty =
          stockObj?.curQuantity + stockObj?.penQuantity + initialQty || 0;
      } else {
        stockQty = stockObj?.curQuantity + stockObj?.penQuantity || 0;
      }

      if (stockQty === 0 || el.reqQty > stockQty) {
        // show snackbar, return
        this.snackbar.issue(
          `Not enough stock for ${el.item.name} at ${this.warehouse.name}`
        );
        materialCheck = false;
      }
    });

    job.timberItems.forEach((el, i) => {
      const stockObj = this.timberStocks[i];
      let stockQty;
      if (
        job._id &&
        this.initialJob.status !== 'draft' &&
        this.initialJob.timberItems[i]
      ) {
        const initialQty = this.initialJob.timberItems[i].reqQty;
        stockQty =
          stockObj?.curQuantity + stockObj?.penQuantity + initialQty || 0;
      } else {
        stockQty = stockObj?.curQuantity + stockObj?.penQuantity || 0;
      }

      if (stockQty === 0 || el.reqQty > stockQty) {
        // show snackbar, return
        this.snackbar.issue(
          `Not enough stock for ${el.item.name} at ${this.warehouse.name}`
        );
        timberCheck = false;
      }
    });
    return timberCheck && materialCheck;
  }

  // create new location and add to the currently selected location field
  onCreateLocation(prefix: string) {
    this.dialog
      .open(LocationFormComponent)
      .afterClosed()
      .subscribe((res) => {
        if (res.status === 'success') {
          const location = res.data as Location;
          this.form.get(`${prefix}Location`).patchValue(location);
        } else {
          // TODO handle error
        }
      });
  }

  // create new contact and add to the currently selected contact field
  onCreateContact() {
    this.dialog
      .open(ContactFormComponent)
      .afterClosed()
      .subscribe(
        (res) => {
          const contact = res.data as Contact;
          this.contacts.push(contact);
          this.form.get('contact').patchValue(contact);
        },
        (error) => {
          this.snackbar.issue(error.error.message);
        }
      );
  }

  getVehicles(): void {
    this.vehicleService.getAllVehicles().subscribe(
      (res: Vehicle[] | null) => {
        if (res) {
          this.vehicles = res;
        } else {
          this.snackbar.issue();
        }
      },
      (err) => {
        this.snackbar.issue(err);
      }
    );
  }

  // getVehicles() {
  //   this.vehicleService.getAllVehicles().subscribe(res => {
  //     if (res.status === 'success') {
  //       this.vehicles = res.data;
  //     } else {
  //       // TODO create error toastr
  //     }
  //   });
  // }

  getContacts() {
    this.contactService.getAllContacts().subscribe((res) => {
      if (res.status === 'success') {
        this.contacts = res.data as Contact[];
      } else {
        // TODO handle error
      }
    });
  }

  getUsers(): void {
    this.userService.getUsersByRole(authRoles.staff).subscribe((res) => {
      if (res.status === 'success') {
        this.assignees = res.data as User[];
      } else {
        // TODO handle error
      }
    });

    this.userService.getUsersByRole(['Driver']).subscribe((res) => {
      if (res.status === 'success') {
        this.employeeList = res.data as User[];
      } else {
        // TODO handle error
      }
    });

    this.userService.getCurrentUser().subscribe((res) => {
      this.assignee.patchValue(res.data);
    });
  }

  onAddTimber() {
    this.jobService.addTimberItem();
  }

  onDeleteTimber(i) {
    this.jobService.deleteTimber(i);
  }

  onAddMaterial() {
    this.jobService.addMaterialItem();
  }

  onDeleteMaterial(i) {
    this.jobService.deleteMaterial(i);
  }

  onClear() {
    this.materialStocks = [];
    this.timberStocks = [];
    this.jobService.resetForm();
  }

  onSubmit() {
    // if the job is draft, enable both locations
    if (!this._id.value) {
      this.fromLocation.enable();
      this.toLocation.enable();
    }

    const job = this.form.value as Job;

    // if the status is in active or review, check the stocks exist

    let submit = true;
    if (this.status.value !== 'draft' && this.type.value === 'delivery') {
      submit = this.checkStocks();
      if (!submit) {
        this.snackbar.issue(`Check items have enough stock`);
      }
    } else if (this.status.value !== 'draft') {
      if (!this.employees.value) {
        submit = false;
        if (!submit) {
          this.snackbar.issue(`Enter employee`);
        }
      }
    }

    if (submit) {
      job.items = job.materialItems.concat(job.timberItems) || [];
      if (!job._id) {
        delete job._id;
        this.jobService.createJob(job).subscribe(
          (data: Job) => {
            this.jobService.resetForm();
            this.dialogRef.close(data);
          },
          (error) => {
            this.snackbar.issue(error.error.message);
          }
        );
      } else {
        this.jobService.updateJob(job).subscribe(
          (data: Job) => {
            this.jobService.resetForm();
            this.dialogRef.close(data);
          },
          (error) => {
            this.snackbar.issue(error.error.message);
          }
        );
      }
    }
  }

  compareFn(optionOne, optionTwo) {
    if (optionOne && optionTwo) {
      return optionOne._id === optionTwo._id;
    }
  }

  get form() {
    return this.jobService.form;
  }

  get _id() {
    return this.form.get('_id');
  }

  get type() {
    return this.form.get('type');
  }

  get status() {
    return this.form.get('status');
  }

  get timberItems() {
    return this.form.get('timberItems') as UntypedFormArray;
  }

  get materialItems() {
    return this.form.get('materialItems') as UntypedFormArray;
  }

  get items() {
    return this.form.get('items') as UntypedFormArray;
  }

  get date() {
    return this.form.get('date').value;
  }

  get assignee() {
    return this.form.get('assignee');
  }

  get employees() {
    return this.form.get('employees');
  }

  get fromLocation() {
    return this.form.get('fromLocation');
  }

  get toLocation() {
    return this.form.get('toLocation');
  }

  get immutable() {
    return this.form.get('immutable');
  }

  get vehicle() {
    return this.form.get('vehicle').value;
  }

  get contact() {
    return this.form.get('contact').value;
  }

  get jobType() {
    return this.form.get('jobType').value;
  }

  get instruction() {
    return this.form.get('instruction').value;
  }

  get photo() {
    return this.form.get('photo').value;
  }
}

/**
 *
 *  OLD FUNCTIONS **************************************************************
 */

// subscribeGetTimberStocks() {
//   const fromLocation$ = this.fromLocation.valueChanges;
//   const timber$ = this.timberItems.valueChanges;

//   combineLatest([fromLocation$, timber$])
//     .subscribe(([location, timberItems]) => {
//       if (location && location._id) {
//         for (const [i, val] of timberItems.entries()) {
//           if (val.item?._id) {
//             if ((this.fromLocation.value._id && this.fromLocation.value._id !== this.prevLocation) || val.item._id !== this.prevItemId) {
//               this.prevLocation = this.fromLocation.value._id;
//               this.prevItemId = val.item._id;

//               this.getStockItems(val.item._id, i, 'timber');
//               // this.checkQty(i, 'timber');

//             }
//           }
//         }
//       }
//     });
// }

// subscribeGetMaterialStocks() {
//   combineLatest([this.form.get('fromLocation').valueChanges, this.form.get('materialItems').valueChanges])
//     .subscribe(([location, materialItems]) => {
//       if (location) {
//         for (const [i, val] of materialItems.entries()) {
//           if (val.item?._id) {
//             if ((this.fromLocation.value._id && this.fromLocation.value._id !== this.prevLocation) || val.item._id !== this.prevItemId) {
//               this.prevLocation = this.fromLocation.value._id;
//               this.prevItemId = val.item._id;

//               this.getStockItems(val.item._id, i, 'material');
//               // this.checkQty(i, 'material');
//             }
//           }
//         }
//       }
//     });
// }

// previous check quantity that sets errors on the form
// checkQty(index, type) {
//   if (type === 'material') {
//     const control = this.materialItems.at(index).get('reqQty');
//     const reqQty = control.value;
//     const stockQty = this.materialStocks[index]?.curQuantity || 0;

//     if (stockQty === 0 || reqQty >= stockQty) {
//       control.setErrors({
//         'noStock': true
//       });
//     } else {
//       control.setErrors(null)
//     }

//   } else {
//     const control = this.timberItems.at(index).get('reqQty');
//     const reqQty = control.value;
//     const stockQty = this.timberStocks[index]?.curQuantity || 0;
//     if (stockQty === 0 || reqQty >= stockQty) {
//       control.setErrors({
//         'noStock': true
//       });
//     } else {
//       control.setErrors(null)
//     }
//   }
// }

// get all stocks at a specific location
// getStocks(location: Location) {
//   // clear stocks in case the from location was switched
//   this.materials = [];
//   this.timbers = [];

//   this.stockService.getAllStocksByLocation(location._id).subscribe((data) => {
//     (data.stocks).forEach((element: Stock) => {
//       if (element.item.type === 'Timber') {
//         this.timbers.push(element);
//       } else {
//         this.materials.push(element);
//       }
//     });
//   })
// }
