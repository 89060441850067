<form [formGroup]="form" (submit)="onSubmit()">
  <h2 mat-dialog-title>
    {{ _id ? 'Edit Contact' : 'Add Contact' }}
  </h2>
  <mat-dialog-content>
    <input type="hidden" formControlName="_id" />
    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="name"
            autocomplete="off"
            placeholder="Name"
          />
          <mat-error>Please enter contact name</mat-error>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field appearance="outline">
          <input
            type="text"
            placeholder="Pick one"
            aria-label="Company"
            matInput
            formControlName="company"
            placeholder="Company (Optional)"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let company of companies" [value]="company">
              {{ company }}
            </mat-option>
          </mat-autocomplete>
          <mat-error>Please enter contact company</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-select formControlName="position" placeholder="Position">
            <mat-option *ngFor="let position of positions" [value]="position">
              {{ position | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error>Please select a contact position</mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="number"
            autocomplete="off"
            placeholder="Number"
          />
          <mat-error>Please enter contact number</mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="mr-2"
      type="button"
      mat-raised-button
      color="warn"
      (click)="onClear()"
    >
      Clear
    </button>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      [disabled]="form.invalid"
    >
      Submit
    </button>
  </mat-dialog-actions>
</form>
