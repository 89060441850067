<h2 mat-dialog-title>
  {{ title }}
</h2>

<div mat-dialog-content>
  <p>{{ message }}</p>
</div>

<div mat-dialog-actions>
  <div class="d-flex justify-content-between">
    <button mat-button (click)="onDismiss()">No</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
  </div>
</div>
