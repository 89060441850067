import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(private http: HttpClient) {}

  // ** HTTP methods **
  // post a image to be created in the backend
  createImage(image): Observable<any> {
    return this.http.post(`${environment.API_BASE_URL}/v1/images`, image, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getImage(id: string) {
    return this.http.get(`${environment.API_BASE_URL}/v1/images/${id}`);
  }

  removeImage(id: string): Observable<any> {
    return this.http.delete(`${environment.API_BASE_URL}/v1/images/${id}`);
  }
}

// post an image to be created in the backend
// createImage(image): Observable<any> {
//   return this.http.post(`${environment.API_BASE_URL}/images/create`, image);
// }

// getImages(imagesId: string[]): Observable<any> {
//   return this.http.post(`${environment.API_BASE_URL}/images/readArray/`, imagesId);
// }
