import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';
import { UserService } from '@core/services';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private userService: UserService, private router: Router) {}

  // intercepts request with noauth and applys the jwt token in the header
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.headers.get('noauth')) {
      return next.handle(req.clone());
    }
    const clonedReq = req.clone({
      headers: req.headers.set(
        'Authorization',
        `Bearer ${this.userService.getToken()}`
      )
    });

    return next.handle(clonedReq).pipe(
      tap(
        event => {
          // no issues
        },
        err => {
          if (err.error.auth === false) {
            // user is not logged in
            this.router.navigateByUrl('/login');
          }
        }
      )
    );
  }
}
