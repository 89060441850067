import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LocationFormComponent } from '@shared/components/location-form/location-form.component';
import { UserFormComponent } from '@shared/components/user-form/user-form.component';
import { ContactFormComponent } from '@shared/components/contact-form/contact-form.component';
import { ItemFormComponent } from '@shared/components/item-form/item-form.component';
import { StockFormComponent } from '@shared/components/stock-form/stock-form.component';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { ToastrModule } from 'ngx-toastr';

import { SharedModule } from '@shared/shared.module';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { GoogleMapsModule } from '@modules/google-maps/google-maps.module';
import { LoginComponent } from './authentication/login/login.component';
import { RegisterComponent } from './authentication/register/register.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AuthGuard } from './guards/auth.guard';
import { UserService } from './services';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ContactService } from './services/contact.service';
import { StockService } from './services/stock.service';
import { MaterialService } from './services/material.service';
import { LocationService } from './services/location.service';
import { MaterialModule } from './material.module';
import { VehicleService } from './services/vehicle.service';
import { JobService } from './services/job.service';
// import { JobFormComponent } from '@shared/components/job-form/job-form.component';
import { ImageService } from './services/image.service';

import { LogService } from './services/log.service';

// core module is a place for other modules that need to be instanciated only once
// put services in this module, but do not provide them. this module is only imported into
// the appmodule file.

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    NavbarComponent,
    UserFormComponent,
    ContactFormComponent,
    LocationFormComponent,
    ConfirmDialogComponent,
    ItemFormComponent,
    // JobSearchComponent,
    // JobFormComponent,
    StockFormComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    MaterialModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    GoogleMapsModule,
    // TODO:G MatGoogleMapsAutocompleteModule,
    ToastrModule.forRoot(),
  ],
  exports: [NavbarComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    AuthGuard,
    UserService,
    ContactService,
    JobService,
    StockService,
    ImageService,
    MaterialService,
    VehicleService,
    LocationService,
    TitleCasePipe,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    LogService,
  ],
})
export class CoreModule { }
