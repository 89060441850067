import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Job } from '@shared/models/job.model';
import { JobService } from '@core/services/job.service';
import { MatDialog } from '@angular/material/dialog';
import { JobFormComponent } from '../job-form/job-form.component';
import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-admin-job-view',
  templateUrl: './admin-job-view.component.html',
  styleUrls: ['./admin-job-view.component.scss']
})
export class AdminJobViewComponent implements OnInit {
  @Input()
  public job: Job;

  initialTime;

  @Output()
  emitter = new EventEmitter();

  constructor(private jobService: JobService, private dialog: MatDialog) {}

  ngOnInit() {
    this.initialTime = this.job.assignedTime;
  }

  onChange() {
    // only update changed fields (locations are immutable etc)
    const updatedJob = {
      _id: this.job._id,
      assignedTime: this.job.assignedTime,
      status: this.job.status
    };
    this.emitter.emit(updatedJob);
  }

  //  TODO: DELETE BOTH METHODS
  public onEdit() {
    this.jobService.populateForm(this.job);
    this.dialog
      .open(JobFormComponent)
      .afterClosed()
      .subscribe(result => {
        if (result ? result.status : false) {
          // const index = this.dataSource.data.findIndex((job: Job) => job._id === result.job._id);
          // if (index > -1) {
          //   // this.dataSource.data[index] = result.job;
          //   // this.dataSource._updateChangeSubscription();
          // }
        }
      });
  }

  public onDelete() {
    let result;
    const message = `Are you sure you want to delete ${this.job.toLocation.name} ?`;
    const dialogData = new ConfirmDialogModel('Delete job', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      result = dialogResult;
      if (result) {
        this.jobService.deleteJob(this.job._id).subscribe(data => {
          if (data.status) {
            // const index = this.dataSource.data.indexOf(job);
            // this.dataSource.data.splice(index, 1);
            // this.dataSource._updateChangeSubscription();
          }
        });
      }
    });
  }
}
