<form [formGroup]="form" (submit)="onSubmit()">
  <h2 mat-dialog-title>
    {{ form.controls['_id'].value ? 'Edit Job' : 'Add Job' }}
  </h2>
  <mat-dialog-content>
    <input type="hidden" formControlName="_id" />

    <!-- assignee, employee, status and date -->
    <div class="row border-bottom mb-5 mt-2">
      <div class="col-md-3">
        <app-dropdown
          *ngIf="assignees"
          class="w-100"
          title="assignee"
          [control]="assignee"
          [array]="assignees"
          [props]="['firstName', 'lastName']"
        >
        </app-dropdown>
        <mat-form-field appearance="outline" *ngIf="employeeList">
          <mat-label>Employees</mat-label>
          <mat-select
            id="employees-selector"
            [formControl]="form.get('employees')"
            [compareWith]="compareFn"
            multiple
          >
            <mat-option *ngFor="let element of employeeList" [value]="element">
              {{ element.firstName }}
              {{ element.lastName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="employees.errors && employees.touched"
            >Invalid</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <app-dropdown
          *ngIf="statusTypes"
          title="status"
          [control]="form.get('status')"
          [array]="statusTypes"
        >
        </app-dropdown>
        <app-dropdown
          *ngIf="types"
          title="type"
          [control]="form.get('type')"
          [array]="types"
        >
        </app-dropdown>
      </div>
      <div class="col-md-3">
        <app-dropdown
          *ngIf="vehicles"
          class="w-100"
          title="vehicle"
          [control]="form.get('vehicle')"
          [array]="vehicles"
          [props]="['make', 'model', 'colour']"
        >
        </app-dropdown>
        <div class="d-flex">
          <app-contact-search
            id="contact-search"
            placeholder="Contact"
            class="w-100"
            [control]="form.get('contact')"
          ></app-contact-search>
          <span
            class="material-icons mt-3 green sp-btn"
            (click)="onCreateContact()"
          >
            add_circle
          </span>
        </div>
      </div>

      <div class="col-md-3">
        <mat-form-field appearance="outline">
          <mat-label>Assign Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            autocomplete="off"
            formControlName="assignedDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Assign Time</mat-label>
          <input
            matInput
            type="time"
            autocomplete="off"
            formControlName="assignedTime"
          />
        </mat-form-field>
      </div>
    </div>

    <!-- locations -->
    <div class="row" *ngIf="type.value">
      <div class="col-md-6">
        <div class="d-flex">
          <app-location-search
            id="from-location-search"
            placeholder="From location"
            class="w-100"
            [control]="fromLocation"
          >
          </app-location-search>
          <span
            class="material-icons mt-3 green sp-btn"
            (click)="onCreateLocation('from')"
          >
            add_circle
          </span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex">
          <app-location-search
            id="to-location-search"
            placeholder="To location"
            class="w-100"
            [control]="form.get('toLocation')"
          >
          </app-location-search>
          <span
            class="material-icons mt-3 green sp-btn"
            (click)="onCreateLocation('to')"
          >
            add_circle
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="type.value; else noType">
      <div
        formArrayName="materialItems"
        class="border rounded p-3 m-1"
        *ngIf="fromLocation"
      >
        <h6>Materials</h6>
        <div
          *ngFor="let element of materialItems.controls; let i = index"
          [formGroupName]="i"
        >
          <app-job-form-item
            [jobItem]="element"
            [timber]="false"
            [stock]="materialStocks[i]"
            (remove)="onDeleteMaterial(i)"
          ></app-job-form-item>
        </div>

        <div class="text-center">
          <button
            type="button"
            mat-button
            color="primary"
            (click)="onAddMaterial()"
          >
            Add
          </button>
        </div>
      </div>

      <!-- timber items -->
      <div
        formArrayName="timberItems"
        class="border rounded p-3 m-1"
        *ngIf="fromLocation"
      >
        <h6>Timbers</h6>
        <div
          *ngFor="let element of timberItems.controls; let i = index"
          [formGroupName]="i"
        >
          <app-job-form-item
            [jobItem]="element"
            [stock]="timberStocks[i]"
            (remove)="onDeleteTimber(i)"
          ></app-job-form-item>
        </div>
        <div class="text-center">
          <button
            type="button"
            mat-button
            color="primary"
            (click)="onAddTimber()"
          >
            Add
          </button>
        </div>
      </div>
    </div>
    <ng-template #noType>
      <app-display
        title="No type"
        [error]="true"
        [props]="['Please select type']"
      >
      </app-display>
    </ng-template>

    <mat-form-field appearance="outline" class="mt-2">
      <mat-label>Instructions</mat-label>
      <textarea
        matInput
        rows="5"
        formControlName="instruction"
        [value]="instruction"
      ></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="d-flex justify-content-between">
    <button
      class="mr-2"
      type="button"
      mat-raised-button
      color="warn"
      (click)="onClear()"
    >
      Clear
    </button>
    <button
      mat-raised-button
      #submit
      type="submit"
      color="primary"
      [disabled]="form.invalid || immutable.value"
    >
      Save
    </button>
  </mat-dialog-actions>
</form>
