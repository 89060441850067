<div class="row py-2">
  <div class="col-sm-1">
    <div class="d-flex h-100 align-items-center justify-content-between">
      <span *ngIf="job.status === 'active'" class="material-icons green">
        hourglass_bottom
      </span>
      <span *ngIf="job.status === 'review'" class="material-icons red">
        assignment
      </span>
      <span *ngIf="job.status === 'draft'" class="material-icons blue">
        drafts
      </span>
    </div>
  </div>

  <div class="col-sm-2">
    <h6>
      Date
      <small class="text-muted d-block">
        {{ job.assignedDate | date }}
        {{ job.assignedTime }}
      </small>
    </h6>
  </div>

  <div class="col-sm-2">
    <h6>
      Employee
      <small class="text-muted d-block" *ngIf="job.employees">
        {{ job.employees[0]?.firstName }} {{ job.employees[0]?.lastName }}
      </small>
    </h6>
  </div>

  <div class="col-sm-2">
    <h6>
      Type
      <small class="text-muted d-block"> {{ job.type }} </small>
    </h6>
  </div>
  <div class="col-sm-2">
    <h6>
      Location
      <small class="text-muted d-block">
        {{
          job.type === 'delivery'
            ? job.toLocation?.name
            : job.fromLocation?.name
        }}
      </small>
    </h6>
  </div>
  <div class="col-sm-3">
    <h6>
      Instruction
      <small class="text-muted d-block">
        {{ job.instruction | slice: 0:50 }}
      </small>
    </h6>
  </div>
</div>
