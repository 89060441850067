import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: [ './table-header.component.scss' ],
})
export class TableHeaderComponent implements OnInit, OnDestroy {
  @Output() search: EventEmitter<string> = new EventEmitter();

  @Output() create: EventEmitter<void> = new EventEmitter();

  @Input() inactiveToggle = true;

  showInactive = false;

  @Output() showInactiveChange: EventEmitter<boolean> = new EventEmitter();

  searchKey = '';

  private searchDebounce: Subject<string> = new Subject();

  private subscription: Subscription;

  ngOnInit(): void {
    this.subscription = this.searchDebounce
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((value) => this.search.emit(value));
  }

  onSearchChange(): void {
    this.searchDebounce.next(this.searchKey);
  }

  onCreate(): void {
    this.create.emit();
  }

  toggle(event: MatSlideToggleChange): void {
    this.showInactive = event.checked;
    this.showInactiveChange.emit(this.showInactive);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
