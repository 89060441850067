import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Contact } from '@shared/models/contact.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private http: HttpClient, private fb: UntypedFormBuilder) {}

  form: UntypedFormGroup = this.getForm();

  getForm(contact?: Contact) {
    return this.fb.group({
      _id: new UntypedFormControl(contact ? contact._id : null),
      name: new UntypedFormControl(
        contact ? contact.name : '',
        Validators.required
      ),
      number: new UntypedFormControl(
        contact ? contact.number : '',
        Validators.required
      ),
      company: new UntypedFormControl(contact ? contact.company : ''),
      position: new UntypedFormControl(
        contact ? contact.position : '',
        Validators.required
      ),
    });
  }

  resetForm() {
    this.form.reset();
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setErrors(null);
    });
  }

  populateForm(contact: Contact) {
    this.form.patchValue(contact);
  }

  // HTTP methods

  // create new contact
  createContact(contact: Contact): Observable<any> {
    return this.http.post(`${environment.API_BASE_URL}/v1/contacts`, contact);
  }

  // get contact by id
  getContactById(id: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/contacts/${id}`);
  }

  // search through contacts
  search(val, limit: string): Observable<any> {
    return this.http.get(
      `${environment.API_BASE_URL}/v1/contacts/search/${val}`,
      {
        params: { limit },
      }
    );
  }

  // get all contacts
  getAllContacts(params?: Params): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/contacts`, {
      params: {
        limit: '300',
        ...params,
      },
    });
  }

  // update contact
  updateContact(contact: Contact): Observable<any> {
    return this.http.patch(
      `${environment.API_BASE_URL}/v1/contacts/${contact._id}`,
      contact
    );
  }

  // get distinct companies
  getContactCompanies(): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/contacts/companies`);
  }

  // get contact positions
  getContactPositions(): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/contacts/positions`);
  }

  // delete contact
  deleteContact(id: string): Observable<any> {
    return this.http.delete(`${environment.API_BASE_URL}/v1/contacts/${id}`);
  }
}
