import { Component, Input, OnInit } from '@angular/core';
import { Job } from '@shared/models/job.model';

@Component({
  selector: 'app-employee-job-view-header',
  templateUrl: './employee-job-view-header.component.html',
  styleUrls: ['./employee-job-view-header.component.scss']
})
export class EmployeeJobViewHeaderComponent implements OnInit {
  @Input()
  public job: Job;

  constructor() {}

  ngOnInit() {}

  get location() {
    if (this.job.type === 'delivery') {
      return this.job.toLocation;
    }
    return this.job.fromLocation;
  }
}
