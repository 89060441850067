<div class="container mt-5">
  <form>
    <div class="form-group">
      <label for="">Myob Code</label>
      <input
        type="text"
        class="form-control"
        id=""
        aria-describedby=""
        placeholder="Enter MYOB code"
        name="myobCode"
        [(ngModel)]="material.myobCode"
      />
    </div>

    <div class="form-group">
      <label for="">Material name</label>
      <input
        type="text"
        class="form-control"
        id=""
        aria-describedby=""
        placeholder="Enter material name"
        name="name"
        [(ngModel)]="material.name"
      />
    </div>

    <div class="form-group">
      <label for="">Material type</label>
      <input
        type="text"
        class="form-control"
        id=""
        aria-describedby=""
        name="type"
        placeholder="Enter material name"
        [(ngModel)]="material.type"
      />
    </div>

    <div class="form-group">
      <label for="">Material unit</label>
      <input
        type="text"
        class="form-control"
        id=""
        aria-describedby=""
        placeholder="Enter material name"
        name="unit"
        [(ngModel)]="material.unit"
      />
    </div>

    <div *ngIf="material.type && material.type == 'timber'">
      <div class="form-group">
        <label for="">Project code</label>
        <input
          type="text"
          class="form-control"
          id=""
          aria-describedby=""
          placeholder="Enter material name"
          name="projectCode"
          [(ngModel)]="material.projectCode"
        />
      </div>
      <div class="form-group">
        <label for="">Area per box</label>
        <input
          type="text"
          class="form-control"
          id=""
          aria-describedby=""
          placeholder="Enter material name"
          name="areaPerUnit"
          [(ngModel)]="material.areaPerUnit"
        />
      </div>
      <div class="form-group">
        <label for="">Size</label>
        <input
          type="text"
          class="form-control"
          id=""
          aria-describedby=""
          placeholder="Enter material name"
          name="timberSize"
          [(ngModel)]="material.timberSize"
        />
      </div>
    </div>
    <button type="submit" class="btn btn-primary" (click)="onSave()">
      Save
    </button>
  </form>

  {{ material | json }}
</div>
