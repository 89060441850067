import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ContactService } from '@core/services/contact.service';
import { Contact } from '@shared/models/contact.model';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-contact-search',
  templateUrl: './contact-search.component.html',
  styleUrls: ['./contact-search.component.scss'],
})
export class ContactSearchComponent implements OnInit {
  options: Contact[] = [];

  timeout = null; // default set

  @Input() placeholder = 'Contact';

  @Output() emitter = new EventEmitter();

  @Input() control = new UntypedFormControl();

  constructor(
    private contactService: ContactService,
    private titlecasePipe: TitleCasePipe
  ) {}

  ngOnInit(): void {
    this.control.valueChanges.subscribe((val) => {
      if (typeof val === 'object') {
        this.emitter.emit(val);
      }
    });
  }

  onKeyup(val) {
    const _this = this;
    // this.contactService;
    // If it has already been set cleared first.
    clearTimeout(this.timeout);

    // set timeout to 1000ms
    this.timeout = setTimeout(function () {
      // call a search to backend for contacts
      _this.search(val);
      console.log('Input Value:', val);
    }, 1000);
  }

  search(val) {
    if (val) {
      this.contactService.search(val, '10').subscribe((res) => {
        if (res.status === 'success') {
          this.options = res.data;
        }
      });
    }
  }

  displayFn(contact: Contact): string {
    if (contact && contact.name) {
      return contact.name;
    }
    return '';
  }
}
