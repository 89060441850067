// / <reference types="@types/googlemaps" />

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import {
  AddressForm,
  AddressFormGroup,
  LocationService,
} from '@core/services/location.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Location } from '@shared/models/location.model';
import { GoogleMapsLoaderService } from '@modules/google-maps/google-maps-loader.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { GoogleMapsAutocompleteComponent } from '@modules/google-maps/google-maps-autocomplete/google-maps-autocomplete.component';
import { SnackbarService } from '@core/services/snackbar.service';

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationFormComponent implements OnInit {
  constructor(
    private locationService: LocationService,
    public dialogRef: MatDialogRef<LocationFormComponent>,
    private googleMapsService: GoogleMapsLoaderService,
    private cdr: ChangeDetectorRef,
    private snackbar: SnackbarService
  ) {}

  public zoom: number;

  public latitude: number;

  public longitude: number;

  public selectedAddress: any;

  private destroy$: Subject<void> = new Subject();

  @ViewChild('autocomplete') autocomplete: GoogleMapsAutocompleteComponent;

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe(() => {
      this.onClear();
    });

    this.googleMapsService.placeResult$
      .pipe(
        tap((placeResult) => {
          const address = processGoogleLocation(placeResult);

          if (!address.streetNumber)
            this.snackbar.issue('Selected location is missing a Street Number');
          if (!address.streetName)
            this.snackbar.issue('Selected location is missing a Street Name');
          if (!address.streetNumber && !address.streetName)
            this.snackbar.issue(
              'Selected location is missing both Street Number and Street Name'
            );

          this.locationService.form.get('address').patchValue(address);
          this.cdr.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onClear() {
    this.autocomplete.clearAutocomplete();
    this.locationService.resetForm();
  }

  onSubmit() {
    const location = this.locationService.form.value as Location;

    if (!location._id) {
      delete location._id;
      this.locationService.createLocation(location).subscribe(
        (data) => {
          this.dialogRef.close(data);
        },
        (error) => {
          this.snackbar.issue(error.error.message);
        }
      );
    } else {
      this.locationService.updateLocation(location).subscribe(
        (res) => {
          this.dialogRef.close(res);
        },
        (error) => {
          this.snackbar.issue(error.error.message);
        }
      );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get form() {
    return this.locationService.form;
  }

  get _id() {
    return this.form.get('_id').value;
  }

  get type() {
    return this.form.get('type');
  }

  get address(): AddressFormGroup {
    return this.form.get('address') as AddressFormGroup;
  }

  get streetNumber() {
    return this.address.get('streetNumber').value;
  }

  get streetName() {
    return this.address.get('streetName').value;
  }

  get shortAddress() {
    if (this.streetName && this.streetNumber) {
      return `${this.streetNumber} ${this.streetName}`;
    }
    return null;
  }
}

function processGoogleLocation(data): AddressForm {
  const componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
  };

  let streetNumber = '';
  let streetName = '';
  let suburb = '';
  let state = '';
  let postCode = '';

  for (const component of data.address_components) {
    const addressType = component.types[0];
    if (componentForm[addressType]) {
      switch (addressType) {
        case 'street_number':
          streetNumber = component[componentForm[addressType]];
          break;
        case 'route':
          streetName = component[componentForm[addressType]];
          break;
        case 'locality':
          suburb = component[componentForm[addressType]];
          break;
        case 'administrative_area_level_1':
          state = component[componentForm[addressType]];
          break;
        case 'postal_code':
          postCode = component[componentForm[addressType]];
          break;
      }
    }
  }

  const lat = data.geometry.location.lat();
  const long = data.geometry.location.lng();
  const placeId = data.place_id;

  const address: AddressForm = {
    streetNumber,
    streetName,
    suburb,
    state,
    postCode,
    lat,
    long,
    placeId,
  };

  return address;
}
