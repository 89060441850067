import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@core/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isAuthenticated = this.userService.isLoggedIn();
    const isAuthorised = next.data?.roles?.includes(this.userService.getRole());
    if (isAuthenticated && isAuthorised) {
      return true;
    }
    this.router.navigateByUrl('/login');
    this.userService.deleteToken();
    return false;
  }
}
