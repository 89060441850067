import { Component, Input, OnInit } from '@angular/core';
import { Stock } from '@shared/models/stock.model';
import { round } from '@shared/utils/helper.util';

@Component({
  selector: 'app-low-stock',
  templateUrl: './low-stock.component.html',
  styleUrls: ['./low-stock.component.scss'],
})
export class LowStockComponent implements OnInit {
  @Input() stock: Stock;

  constructor() { }

  current;

  pending;

  estimate;

  ngOnInit(): void {
    this.current = this.stock.curQuantity || 0;
    this.pending = this.stock.penQuantity || 0;
    this.estimate = this.current + this.pending;
  }

  get getEstimatedStockLevel(): number {
    return round(this.estimate);
  }

  get getCurrentStockLevel(): number {
    return round(this.current);
  }

  get getOrderAmount(): number {
    return round(this.stock.item.minStock - this.stock.curQuantity);
  }
}
