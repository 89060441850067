import { Component } from '@angular/core';
import { UserService } from '@core/services';
import { Router } from '@angular/router';
import { STAFF } from 'app/app.contstants';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  constructor(private userService: UserService, private router: Router) {}

  isLoggedIn(): boolean {
    return this.userService.isLoggedIn();
  }

  isStaff(): boolean {
    return this.userService.hasPermission(STAFF);
  }

  logout(): void {
    this.userService.deleteToken();
    this.router.navigate(['/login']);
  }
}
